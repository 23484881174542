<style>
    i.menu-dialog-icon {
        font-size: 25px;
    }
    .menu_mgmt_tree ul.dragArea {
        min-height: 30px;
        border: dotted 1px #660066;
        width: 500px;
        overflow: hidden;
        padding-left: 20px;
    }
    .menu_mgmt_tree > ul.dragArea{
        padding-left: 0;
    }
    .menu_mgmt_tree ul.dragArea li {
        list-style-type: none;
        padding-top: 20px;
    }
    .menu_mgmt_tree ul.dragArea li ul.dragArea li {
        padding-left: 10px;
    }
</style>
<template>
    <regular-page v-bind:pageTitle="'Manage Menu'">
        <div class="row">
            <div class="col-xs-6 menu_mgmt_tree">
                <menu-mgmt-item :subs="this.$data.menuList" 
                
                v-on:update-list="updateList"
                v-on:menu-item-updated="refreshList"
                v-bind="{id:0}" 
                />
            </div>
        </div>
    </regular-page>
</template>

<script>
    import MenuMgmtItem from "./menu_management/MenuMgmtItem";
    export default {
        components: {
            'menu-mgmt-item': MenuMgmtItem
        },
        name: "menu-management",
        display: "Nested",
        order: 15,
        data: function(){
            return {
                menuList: JSON.parse(JSON.stringify(this.$store.state.menu.items))
            };
        },
        computed: {
            storeList: function(){
                return this.$store.state.menu.items;
            }
        },
        watch: {
            storeList: function(newList, oldList)
            {
                this.$data.menuList = JSON.parse(JSON.stringify(newList));
                this.$store.dispatch('app/hideOverlayMessage');
            }
        },
        methods: {
            updateList: function(){
                var me = this;
                var newListPositions = me.menuBreakdown(me.menuList);
                
                me.$store.dispatch('app/overlayMessage', {
                    message: 'Submitting menu changes, please wait...', 
                    spinner: true 
                });
                
                axios.put('/menus/list', qs.stringify({ "newListPositions": newListPositions }), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    me.refreshList();
                });
            },
            menuBreakdown: function(newList, parentIdentifier){
                var me = this;
                var flatList = [];
                
                for(var x = 0; x < newList.length; x++){
                    
                    flatList.push({ id : newList[x].id, parent_identifier : parentIdentifier, identifier: newList[x].identifier , position: x + 1 });

                    if(newList[x]['subs'].length > 0){
                        var subFlat = me.menuBreakdown(newList[x]['subs'],newList[x].identifier);

                        for(var y=0;  subFlat.length > y; y++){
                            flatList.push(subFlat[y]);
                        }
                    }
                }

                return flatList;
            },
            refreshList: function()
            {
                this.$store.dispatch('app/overlayMessage', {
                    message: 'Menu Refreshing, please wait...', 
                    spinner: true 
                });
                this.$store.dispatch('menu/refreshMenuData');
            }
        }
    };
</script>
<template>
<form class="form-inline" v-on:submit.prevent="updateRowsPerPage">
    <select 
        v-on:change.prevent="updateRowsPerPage"
        v-model="currentRowsPerPage" 
        class="form-control form-control-sm mr-sm-2" 
        placeholder="Rows per page" 
    >
        <option v-for="perPage in perPageOptions" v-bind:value="perPage">{{ perPage }} rows per page</option>
    </select>
</form>
</template>
<script>
export default {
    props: { 
        perPageOptions: {
            type: Array,
            default: function(){ return [10, 25, 75, 100] }
        },
        rowsPerPage: {
            type: Number,
            default: 25
        }
    },
    data: function(){
        return {
            currentRowsPerPage: this.rowsPerPage
        } 
    },
    methods: {
        updateRowsPerPage: function(){
            this.$emit('rows-per-page-change', this.currentRowsPerPage);
        }
    }
}
</script>
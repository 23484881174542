<template>
    <select class="form-control form-control-sm" ref="select-form" v-model="select_value" v-on:change="updateValue">
        <option v-for="option in options_result" v-bind:key="option.value" v-bind:value="option.value">{{ option.text }}</option>
    </select>
</template>
<script>
    import qs from 'qs';
    export default {
        props: {
            value: { type: String, default: '' },
            default: { type: String, default: '' },
            options: { type: Array, default: function(){ return []; }},
            ajax: { type: Object, default: function(){ return { url: '', params: {} }; }}
        },
        data: function(){
            return {
                select_value: this.value ? this.value : this.default,
                options_result: JSON.parse(JSON.stringify(this.options))
            }
        },
        methods: {
            clear: function()
            {
            },
            notifyApplied: function()
            {
                if(this.default != this.select_value)
                {
                    this.$emit('filter-applied');
                }
                else
                {
                    this.$emit('filter-unapplied');
                }
            },
            updateValue: function()
            {
                this.notifyApplied();
                this.$emit('input', this.select_value);
            }
        },
        created(){
            this.notifyApplied();
            this.$emit('input', this.select_value);
        },
        mounted(){
            var me = this;
            if(this.ajax.url)
            {
                axios.post(this.ajax.url, qs.stringify(this.ajax.params), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    if(response.data && typeof response.data.success)
                    {
                        for(var op in response.data.data)
                        {
                            me.options_result.push(response.data.data[op]);
                        }
                    }
                });
            }
        }
    }
</script>
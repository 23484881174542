
<style>
    .summary-form .datepicker-calendar {
        z-index: 3000
    }
	.show_more{
		text-decoration: underline;
		cursor: pointer;
		color: #0000EE;
		padding-top: 2px;
	}
</style>
<template>
<dialog-modal v-on="$listeners">
    <strong slot="header">Hawb Summary<br /><small>Hawb Number: {{ hawbNumber }}</small></strong>
   <form slot="body" name="summary-form">
		<div class="row">
			<div class="col-sm-6">
			   <strong>Info:</strong><hr class="mt-1 mb-2" />
				<div>
					<label class="col-sm-12 col-form-label" style="padding-left:4px; padding-bottom: 2px;">{{summary.location_label}}: </label>
					<div class="col-sm-12">
						<div v-if="summary.agentType=='pickup'" class="container" style="padding-left:4px; padding-bottom: 10px;">
							<div>
								{{summary.shipper_name}} <br />
								{{summary.shipper_address}}<br />
								{{summary.shipper_city}}, {{summary.shipper_state}} {{summary.shipper_zip}} 
							
							</div>
						
						</div>
						<div v-else class="container" style="padding-left:4px; padding-bottom: 10px;" >
							<div>
								{{summary.consignee_name}} <br />
								{{summary.consignee_address}}<br />
								{{summary.consignee_city}}, {{summary.consignee_state}} {{summary.consignee_zip}} 
							
							</div>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-sm-6 col-form-label">{{summary.date_label}}: </label>
					<div class="col-sm-6">
						 <datepicker 
                            v-bind:input-class="{'form-control':true, 'form-control-sm':true, 'color-bg-white':true }" 
                            v-bind:calendar-class="'datepicker-calendar'" 
                            v-bind:bootstrap-styling="true" 
                            v-model="summary.pickup_date"
							format="MM/dd/yyyy"							
							disabled
                        ></datepicker>
					</div>
				</div>
				<div v-if="summary.agentType=='delivery'" class="form-group row">
					<label class="col-sm-6 col-form-label">Appointment Info: </label>
					<div class="col-sm-6">
						<textarea
							:class="{'form-control':true, 'form-control-sm':true }"
							v-model="summary.apptnotes" disabled></textarea>
						
					</div>
				</div>

			</div>
			<div class="col-sm-6">
			   <strong>HAWB Details: </strong><hr class="mt-1 mb-2" />
				<div class="form-group row">
					<label class="col-sm-6 col-form-label">Pcs: </label>
					<div class="col-sm-6">
						<input
						 :class="{'form-control':true, 'form-control-sm':true }"
						v-model="summary.pieces" disabled/>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-sm-6 col-form-label">Weight: </label>
					<div class="col-sm-6">
						<input
						:class="{'form-control':true, 'form-control-sm':true }"
						v-model="summary.weight" disabled />
					</div>
				</div>
				<div class="form-group row">
					<label class="col-sm-6 col-form-label">Pallets: </label>
					<div class="col-sm-6">
						<input 
						:class="{'form-control':true, 'form-control-sm':true }"
						v-model="summary.pallets" disabled />
					</div>
				</div>
	
			</div>

			<div class="col-sm-6">
				 <strong>{{summary.instruction_label}}: </strong><hr class="mt-1 mb-2" />
				 <div class="form-group">
					<div class="col-sm-12">
						
						{{summary.instruction}}
					</div>

				 </div>
			</div>
			<div class="col-sm-6">
				<strong>PO Details: </strong><hr class="mt-1 mb-2" />
				<div class="form-group">
					<div class="col-sm-12">
						<div v-if="summary.showLess">
							<li v-for="(item, index) in summary.pos.slice(0,15)">
								{{ item }}
							</li>
							
							<div class="show_more"
								v-on:click="summary.showLess=false"
								>
								More
							</div> 
						</div>
						<div v-else> 
							<li v-for="(item, index) in summary.pos">
								{{ item }}
							</li>
							
						</div>
					
					</div>
				</div>
			</div>
		</div>
    </form>

    <button slot="footer" v-on:click="$emit('close')" type="button" class="btn btn-secondary btn-sm float-left" v-bind:disabled="isSubmitting">Close</button>
</dialog-modal>
</template>
<script>
import { required, integer, minValue } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker';
import Dialog from './DialogModal';
export default {
	components: {
        Datepicker
       
    },
    props: {
        hawbId: { type: Number, default: 0 },
        hawbNumber: { type: String, default: '' },
        hawb_date: { type: String, default: '' },
		due_date: { type: String, default: '' },
        pieces: { type: Number, default: 0 },
        weight: { type: Number, default: 0 },
        pallets: { type: Number, default: 0 },
		pickup_location: {type: String, default:''},
		apptnotes: {type: String, default:''},
		pos: {type:Array, default:[]},
		pickup_instruction: {type: String, default:''},
		delivery_location: {type: String, default:''},
		delivery_instruction: {type: String, default:''},
		location_label: {type: String, default:''},
		date_label: {type: String, default:''},
		instruction_label: {type: String, default:''},
        agentType: { type: String, required: true }, //Either "pickup" or "delivery",
        showLess:{type:Boolean, default:false},
		shipper_name: {type: String, default:''},
		shipper_address: {type: String, default:''},
		shipper_city:{type: String, default:''},
		shipper_zip: {type: String, default:''},
		shipper_state: {type: String, default:''},
		consignee_name: {type: String, default:''},
		consignee_address: {type: String, default:''},
		consignee_city: {type: String, default:''},
		consignee_zip: {type: String, default:''},
		consignee_state: {type: String, default:''},
    },
    data: function(){
        return {
            summary: {
                hawb_id: this.hawbId,
                hawb_number: this.hawbNumber,
                pieces: this.pieces,
				weight: this.weight,
				pallets: this.pallets,
				pickup_location: this.pickup_location,
				delivery_location: this.delivery_location,
				apptnotes: this.apptnotes,
				pos:this.pos,
				agentType: this.agentType,
				pickup_date: this.agentType=='pickup'? this.hawb_date: this.due_date,
				pickup_instruction: this.pickup_instruction,
				delivery_instruction: this.delivery_instruction,
				instruction: this.agentType=='pickup'? this.pickup_instruction: this.delivery_instruction,
				location: this.agentType=='pickup'? this.pickup_location: this.delivery_location,
				location_label: this.agentType=='pickup'? 'Pickup Location': 'Delivery Location',
				date_label: this.agentType=='pickup'? 'Pickup Date': 'Due Date',
				instruction_label: this.agentType=='pickup'? 'Pickup Instructions': 'Delivery Instructions',
				showLess:this.showLess,
				shipper_name: this.shipper_name,
				shipper_address: this.shipper_address,
				shipper_city: this.shipper_city,
				shipper_zip: this.shipper_zip,
				shipper_state: this.shipper_state,
				consignee_name: this.consignee_name,
				consignee_address: this.consignee_address,
				consignee_city: this.consignee_city,
				consignee_zip: this.consignee_zip,
				consignee_state: this.consignee_state,
            },
            submitted: false,
            isSubmitting: false
        };
    }
}
</script>
<template>
    <div class="btn-group" role="group" aria-label="Actions">
        <button v-on:click="submitRemark" type="button" class="btn btn-sm btn-outline-secondary" data-toggle="tooltip" data-placement="top" title="Submit Pickup Charge Remark">
            Submit Remark
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            chargeData: { 
                type: Object, 
                default: function(){ return {
                    charge_id: 0
                }
            }}
        },
        methods: {
            submitRemark: function()
            {
                this.$emit("submit-charge-remark", this.chargeData);
            }
        }
    }
</script>
<template>
    <div class="row">
        <datepicker
            ref="from_date"
            v-bind:wrapper-class="'col-md-6'"
            v-bind:input-class="'form-control-sm color-bg-white'" 
            v-bind:calendar-class="'datepicker-calendar'" 
            v-bind:bootstrap-styling="true"
            v-bind:format="formatDate"
            v-model="startDate"
            @input="updateValue"
        ></datepicker>
        <datepicker 
            ref="to_date"
            v-bind:wrapper-class="'col-md-6'"
            v-bind:input-class="'form-control-sm color-bg-white'" 
            v-bind:calendar-class="'datepicker-calendar'" 
            v-bind:bootstrap-styling="true"
            v-bind:format="formatDate"
            v-model="endDate"
            @input="updateValue"
        ></datepicker>
    </div>
</template>
<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        components: {
            Datepicker
        },
        props: {
            value: { type: String, default: '' },
            start: { type: Object, default: function(){ return { default: '' }; }},
            end: { type: Object, default: function(){ return { default: '' }; }},
        },
        data: function(){
            var cur_val = (this.value) ? JSON.parse(this.value) : '';

            return {
                startDate: (cur_val && cur_val.start) ? moment(cur_val.start).format() : (this.start.default ? moment(this.start.default).format() : ""),
                endDate: (cur_val && cur_val.end) ? moment(cur_val.end).format() : (this.end.default ? moment(this.end.default).format() : "")
            }
        },
        watch: {
            value: function(){
                this.notifyApplied();
            }
        },
        methods: {
            formatDate: function(date){
                return date ? moment(date).format('L') : '';
            },
            getDateValue: function()
            {
                var startVal = (this.startDate) ? moment(this.startDate).format("YYYY-MM-DD") : '';
                var endVal = (this.endDate) ? moment(this.endDate).format("YYYY-MM-DD") : '';
                
                return {
                    start: startVal,
                    end: endVal
                };
            },
            notifyApplied: function()
            {
                var cur_val = this.getDateValue();
                if(cur_val.start != this.start.default || cur_val.end != this.end.default){
                    this.$emit('filter-applied');
                } else {
                    this.$emit('filter-unapplied');
                }
            },
            updateValue: function()
            {
                this.$emit('input', JSON.stringify(this.getDateValue()));
            },
            prependRangeLabels: function()
            {
                //From
                var from_div = document.createElement("div");
                var from_span = document.createElement("span");
                from_div.classList.add("input-group-prepend");
                from_span.classList.add("input-group-text");
                from_span.append("From");
                from_div.append(from_span);
                this.$refs.from_date.$el.getElementsByClassName("input-group")[0].classList.add("input-group-sm");
                this.$refs.from_date.$el.getElementsByClassName("input-group")[0].prepend(from_div);
                
                //To
                var to_div = document.createElement("div");
                var to_span = document.createElement("span");
                to_div.classList.add("input-group-prepend");
                to_span.classList.add("input-group-text");
                to_span.append("To");
                to_div.append(to_span);
                this.$refs.to_date.$el.getElementsByClassName("input-group")[0].classList.add("input-group-sm");
                this.$refs.to_date.$el.getElementsByClassName("input-group")[0].prepend(to_div);
            }
        },
        created() {
            this.$emit('input', JSON.stringify(this.getDateValue()))
            this.notifyApplied();
        },
        mounted(){
            this.prependRangeLabels();
        }
    }
</script>
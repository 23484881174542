<template>
<dialog-modal v-on="$listeners">
    <strong slot="header">Search Agents</strong>
    <form slot="body" name="agent-search-form" v-on:submit.prevent>
        <fieldset v-bind:disabled="isProcessing">
            <div class="input-group">
                <input 
                    type="search" 
                    ref="search_input" 
                    placeholder="Please type agent name or number and click Search" 
                    v-model="search_term" 
                    aria-describedby="button-addon1" 
                    v-bind:class="{'form-control':true, 'is-invalid' : !validSearch(search_term) && search_blured}" 
                    v-on:blur="search_blured = true"
                >
                <div class="invalid-feedback order-last">A search term with minimum of 3 characters is required</div>
                <div class="input-group-append">
                    <button id="button-addon1" v-on:click="submitSearch($event)" type="submit" class="btn btn-link text-primary"><i class="fa fa-search"></i></button>
                </div>
            </div>
        </fieldset>
    </form>
    <div slot="body" v-if="isProcessing" class="text-center pt-3">
        <spinner></spinner>
    </div>
    <div slot="body" v-if="agents.length > 0" class="list-group pt-3">
        <a v-on:click="selectAgent(agent)" href="#" v-for="agent in agents" v-bind:key="agent.id" class="list-group-item  list-group-item-action flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{agent.name}}</h5>
                <small v-if="agent.number" class="text-muted">
                    <small><strong>NUMBER:</strong></small>
                    <span class="text-monospace"> {{agent.number}}</span>
                </small>
            </div>
            <p class="mb-1">
                <span v-if="agent.address_1 && agent.address_1.trim()">{{agent.address_1}}<br /></span>
                <span v-if="agent.city && agent.city.trim()">{{agent.city}}, </span>
                <span v-if="agent.state && agent.state.trim()">{{agent.state}} </span>
                <span v-if="agent.zip && agent.zip.trim()">{{agent.zip}}</span>
                <span v-if="agent.country_name && agent.country_name.trim()"><br />{{agent.country_name}}</span>
            </p>
            <small v-if="agent.contact.trim() > 0" class="text-muted pr-1">
                <small><strong>CONTACT:</strong></small>
                <span class="text-monospace"> {{agent.contact}}</span><br />
            </small>
            <small v-if="agent.phone_nbr.trim()" class="text-muted pr-1">
                <small><strong>PHONE:</strong></small> 
                <span class="text-monospace">{{agent.phone_nbr}}</span>
            </small>
        </a>
    </div>
    <button slot="footer" v-on:click="$emit('close')" type="button" class="btn btn-secondary btn-sm float-left">Cancel</button>
</dialog-modal>
</template>
<script>
import qs from 'qs';
export default {
    props: {
        onAgentSelect: {
            type: Function,
            default: function(){}
        }
    },
    data: function(){
        return {
            is_processing: false,
            search_term: "",
            search_blured: false,
            valid: false,
            agents: []
        }
    },
    computed: {
        isProcessing: function(){
            return this.$data.is_processing;
        }
    },
    watch: {
    },
    methods: {
        validSearch: function(){
            return (this.search_term.length > 2) ? true : false;
        },
        validate: function(){
            this.search_blured = true;
            if(this.validSearch(this.search_term))
            {
                this.valid = true;
            }
            else
            {
                this.valid = false;
            }
        },
        submitSearch: function(event){
            var me = this;
            me.validate();
            if(me.valid)
            {
                this.$data.is_processing = true;
                me.$data.agents = [];
                axios.post('/agent/search', qs.stringify({ "search": this.$data.search_term }), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    if(response.data)
                    {
                        for(var a in response.data.data)
                        {
                            me.$data.agents.push(response.data.data[a]);
                        }
                    }
                    me.is_processing = false;
                });
            }
        },
        selectAgent: function(agent){
            var me = this;
            this.onAgentSelect(JSON.parse(JSON.stringify(agent)));
            this.$emit('close');
        }
    },
    mounted: function(){
        this.$refs.search_input.focus()
    }
}
</script>
<template>
    <VueDraggable class="dragArea list-group" style="min-height:20px;" :list="subs" :group="{ name: 'menu' }" @end="onEnd" ref="draggable">
        <div class="list-group-item pb-0 mb-2 mt-2" v-for="(el) in subs" :key="el.id">
            
            <div class="btn-group" role="group" aria-label="...">
                <button type="button" class="btn btn-secondary btn-sm" @click="editDialog(el, $event)">
                    <i v-bind:class="'fas '+el.icon+' mr-1'"></i><span :title="'Edit this item'" :name="el.identifier">{{ el.label }}</span>
                </button>
                <button slot="header" class="btn btn-sm btn-outline-info" :title="'Add new submenu item'"  :name="el.identifier" @click="createMenuItemDialog(el, $event)">
                    <i class="fas fa-plus"></i>
                </button>
                 <button slot="header" class="btn btn-sm btn-outline-danger" :title="'Delete this item and it\'s children'" @click="deleteDialog(el, $event)">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
            <menu-mgmt-item :subs="el.subs" v-bind="el" v-on:menu-item-updated="$emit('menu-item-updated')" v-on:update-list="$emit('update-list')" />
        </div>
    </VueDraggable>
</template>
<script>
import VueDraggable from "vuedraggable";
import MenuItemDialog from "../menu_management/MenuItemDialog";
import Dialog from "../DialogModal";
export default {
    components: {
        VueDraggable
    },
    props: {
        id: { type: Number, default: 0 },
        identifier: { type: String, default: '' },
        parent_identifier: { type: String, default: '' },
        label: { type: String, default: '' },
        component: { type: String, default: '' },
        title: { type: String, default: '' },
        description: { type: String, default: '' },
        additional_class: { type: String, default: '' },
        icon: { type: String, default: '' },
        device: { type: String, default: '' },
        position: { type: Number, default: 0 },
        created_at: { type: String, default: '' },
        updated_at: { type: String, default: '' },
        subs: { type: Array, required: true },
        roles: { type: Array },
    },
    methods: {
        onEnd: function(event){
            this.$emit('update-list');
        },
        deleteDialog(item, event) {
            var me = this;
            
            me.$modal.show(Dialog, {
                title: "Delete Menu Item",
                message: "Are you sure you want to delete " + item.label + " ?",
                type: 'warning',
                buttons: [{
                    label: 'Yes, Delete ' + item.label,
                    align: "right",
                    action: function(dialog){
                        axios.delete('/menus/' + item.id, qs.stringify({ "id": item.id }), {
                            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                        }).then(function(response){
                            dialog.$emit("close");
                            me.$emit('menu-item-updated');
                            me.is_processing = false;
                        });
                    }
                }]
            }, {
                height: 'auto',
                adaptive: true
            });
        },
        createMenuItemDialog(item, event) {
            var me = this;
            
            this.$modal.show(MenuItemDialog, {
                parent_identifier: item.identifier,
                onSubmit: function(menu_item){
                    me.$emit('menu-item-updated');
                }
            },{
                height: 'auto',
                scrollable: true,
                adaptive: true
            });
        },
        editDialog(item, e){
            e.preventDefault();
            let me = this;
            me.$modal.show(MenuItemDialog, {
                id: item.id,
                identifier: item.identifier,
                parent_identifier: item.parent_identifier,
                label: item.label,
                component: item.component,
                title: item.title,
                description: item.description,
                additional_class: item.additional_class,
                position: item.position,
                icon: item.icon,
                device: [{
                    name: 'desktop',
                    checked: item.device.split(',').includes('desktop'),
                    icon: 'fa-desktop'
                },{
                    name: 'tablet',
                    checked: item.device.split(',').includes('tablet'),
                    icon: 'fa-tablet'
                },{
                    name: 'phone',
                    checked: item.device.split(',').includes('phone'),
                    icon: 'fa-mobile'
                }],
                roles: item.roles,
                onSubmit: function(menu_item){
                    me.$emit("close");
                    me.$emit('menu-item-updated');
                },
            },{
                height: 'auto',
                scrollable: true,
                adaptive: true
            }); 
        }
    }
};

</script>
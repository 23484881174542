<template>
    <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon2" id="username">
        <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" id="username_search" v-on:click="search_user">Search</button>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'login-as',
    
    methods:{
            search_user: function(){

                var username = $("#username").val();
                 axios.post("/user/getUser", {
                        Name: username
                    }).then(function(response){
                        this.$emit('select-user',response.data.data);
                    });
               
            }
    }
}
</script>
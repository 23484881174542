<template>
    <regular-page v-bind:pageTitle="'Shipment Deliveries'">
        <template v-slot:title-bar>
            <div class="btn-group btn-group-sm" role="group" aria-label="Page Submenu">
                <button v-on:click="exportToExcel" type="button" class="btn btn-secondary">
                    Export&nbsp;&nbsp;<i class="fas fa-file-export"></i>
                </button>
            </div>
        </template>
        <div class="row">
            <div class="col-xs-12 w-100">
                <d-table 
                    ref="deliveries_table"
                    v-bind:tableId="'table_deliveries'"
                    v-bind:saveState="true"
                    v-bind:serverSide="true"
                    v-bind:paging="true"
                    v-bind:pageLength="25"
                    v-bind:scrollY="'400px'"
                    v-bind:ajax="'/deliveries/list'"
                    v-bind:columns="columns"
                    v-bind:filters="filters"
                    v-bind:shortcutFilters="shortcutFilters"
                    v-bind:rowIdKey="'hawb_id'"
                    v-bind:order="[{ column: 2, dir: 'asc' }]"
                    v-on="$listeners"
                    v-on:delivery-confirm="confirmDelivery"
                    v-on:delivery-attach-doc="attachDocument"
                    v-on:delivery-view-doc="viewDocuments"
                    v-on:delivery-request-auth="requestAuth"
                    v-on:delivery-report-osd="reportOsd"
                    v-on:hawb-details-click="summary"
                    v-bind:tableClass="{'table-sm':true}"
                ></d-table>
            </div>
        </div>
    </regular-page>
</template>

<script>
    import DTable from './dtable/Table';
    import DeliveryConfirmDialog from './DeliveryConfirmDialog';
    import AttachDocumentDialog from './AttachDocumentDialog';
    import ViewDocumentsDialog from './ViewDocumentsDialog';
    import RequestAuthDialog from './RequestAuthDialog';
    import ReportOsdDialog from './ReportOsdDialog';
	import SummaryDialog from './SummaryDialog';
    export default {
        components: {
            'd-table': DTable
        },
        data: function(){
            return {
                columns: [{ 
                    title: 'Hawb', 
                    data: 'hawb_number', 
                    name: 'hawb_number',
					renderer: 'hawb-button',
					rendererProps: {
                        indicateApptNotes: true
                    },
                    orderable: true
                },{ 
                    title: 'Status', 
                    data: 'status', 
                    name: 'status',
                    renderer: 'status-cell',
                    orderable: true
                },{ 
                    title: 'Appt Date', 
                    data: 'appt_date_time', 
                    name: 'appt_date_time',
                    renderer: 'html',
                    rendererProps: {
                        wrapOpen: '<div style="white-space:nowrap;">',
                        wrapClose: '</div>'
                    },
                    orderable: true
                },{ 
                    title: 'Driver', 
                    data: 'driver', 
                    name: 'driver',
                    orderable: true
                },{ 
                    title: 'Location', 
                    data: 'consignee_name', 
                    name: 'consignee_name',
                    orderable: true,
                    renderer: 'location',
                    rendererProps: {
                        name: '{{data}}',
                        address: '{{row.consignee_address}}'
                    }
                },{ 
                    title: 'City', 
                    data: 'consignee_city', 
                    name: 'consignee_city',
                    orderable: true
                },{ 
                    title: 'Zip', 
                    data: 'consignee_zip', 
                    name: 'consignee_zip',
                    orderable: true
                },{ 
                    title: 'Pallets', 
                    data: 'pallets', 
                    name: 'pallets',
                    orderable: true,
                    renderer: 'number',
                    rendererProps: {
                        locale: 'en-US'
                    }
                },{ 
                    title: 'Pieces', 
                    data: 'pieces', 
                    name: 'pieces',
                    orderable: true,
                    renderer: 'number',
                    rendererProps: {
                        locale: 'en-US'
                    }
                },{ 
                    title: 'Weight', 
                    data: 'weight', 
                    name: 'weight',
                    orderable: true,
                    renderer: 'number',
                    rendererProps: {
                        locale: 'en-US'
                    }
                },{ 
                    title: 'Actions', 
                    data: 'hawb_id', 
                    name: 'actions',
                    renderer: 'delivery-actions',
                    rendererProps: {
                        hawbData: "{{row}}"
                    }
                }],
                shortcutFilters: [{
                    label: "Appointment",
                    type: "DateRangeToggleFilter",
                    name: "appt_date",
                    props: {
                        ranges: [{
                            active: true,
                            tooltip: "Show All",
                            label: "All"
                        },{
                            active: false,
                            startAtDay: 0,
                            endAtDay: 0,
                            tooltip: "Today",
                            label: '<i class="fas fa-calendar"></i>'
                        },{
                            active: false,
                            startAtDay: 1,
                            endAtDay: 1,
                            tooltip: "Tomorrow",
                            label: '<i class="fas fa-calendar-day"></i>'
                        },{
                            active: false,
                            startAtDay: 0,
                            endAtDay: 6,
                            tooltip: "Next 7 Days",
                            label: '<i class="fas fa-calendar-week"></i>'
                        }]
                    }
                }],
                filters: [
                {
                    label: "Appointment Date",
                    type: "DateRangeFilter",
                    name: "appt_date",
                    props: {
                        start: {
                            default: ""
                        },
                        end: {
                            default: ""
                        }
                    }
                },
                {
                    label: "Appointment Time",
                    type: "TimeRangeFilter",
                    name: "appt_time",
                    props: {
                        start: {
                            default: ""
                        },
                        end: {
                            default: ""
                        }
                    }
                },{
                    label: "Delivered",
                    type: "SelectFilter",
                    name: "delivered",
                    props:
                    {
                        default: "no",
                        options: [{
                            text: "All",
                            value: "all"
                        },{
                            text: "Yes",
                            value: "yes"
                        },{
                            text: "No",
                            value: "no"
                        }]
                    }
                },{
                    label: "Location Name",
                    type: "LocationFilter",
                    name: "location_filter",
                    props: {
                        default: "",
                    }
                }]
            }
        },
        methods: {
            exportToExcel: function(){
                var me = this;
                
                me.$store.dispatch('app/overlayMessage', {
                    message: 'Exporting data to Excel, please wait...',
                    spinner: true
                });
                
                var lastRequestData = me.$refs.deliveries_table.getLastRequest();
                lastRequestData.start = 0;
                lastRequestData.length = 0;
                axios.post("/deliveries/export_excel", qs.stringify(lastRequestData), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    responseType: "blob"
                }).then(function(response){
                    me.$store.dispatch('app/hideOverlayMessage');
                    const type = response.headers['content-type']
                    var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: type, encoding: 'UTF-8' }));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'deliveries_'+moment().format('YYYY_MM_DD_h_mm_ss')+'.xlsx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }).catch(function(){
                    me.$store.dispatch('app/hideOverlayMessage');
                });
            },
            pad: function(n, width, z){
                z = z || '0';
                n = n + '';
                return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
            },
            attachDocument: function(hawb_data){
                var me = this;
                this.$modal.show(AttachDocumentDialog, {
                    dialogTitle: "Attach Document",
                    dialogSubTitle: "Hawb Number: "+hawb_data.hawb_number,
                    entityId: parseInt(hawb_data.hawb_id),
                    entityType: "hawb",
                    allowedExtentions: ['pdf','jpg','jpeg'],
                    docTypeSelected: "pod",
                    docTypeOptions: [{value: "pod", label: "POD"}],
                    onSubmit: function(values){
                        me.$refs.deliveries_table.reRequestData();
                    }
                },{ 
                    height: 'auto',
                    scrollable: true,
                    adaptive: true,
                    clickToClose: false
                });
            },
            viewDocuments: function(hawb_data){
                var me = this;
                this.$modal.show(ViewDocumentsDialog, {
                    dialogTitle: "View Documents",
                    dialogSubTitle: "Hawb Number: "+hawb_data.hawb_number,
                    entityId: parseInt(hawb_data.hawb_id),
                    entityType: "hawb",
                    docTypes: ["pod"],
                    onAttachNewProps: hawb_data,
                    onAttachNew: function(props){
                        me.attachDocument({
                            hawb_id: props.hawb_id, 
                            hawb_number: props.hawb_number
                        });
                    }
                },{ 
                    height: 'auto',
                    scrollable: true,
                    adaptive: true,
                    clickToClose: false
                });
            },
            confirmDelivery: function(hawb_data){
                var me = this;
                this.$modal.show(DeliveryConfirmDialog, {
                    hawbId: parseInt(hawb_data.hawb_id),
                    hawbNumber: hawb_data.hawb_number,
                    podDate: moment().format(),
                    podTime: moment().format("HH")+":"+me.pad(Math.floor(parseInt(moment().format("m"))/5)*5, 2),
                    podName: "",
                    pieces: hawb_data.pieces ? hawb_data.pieces : '',
                    onSubmit: function(values){
                        me.$refs.deliveries_table.reRequestData();
                    }
                },{ 
                    height: 'auto',
                    scrollable: true,
                    adaptive: true
                });
            },
            requestAuth: function(hawb_data){
                var me = this;
                this.$modal.show(RequestAuthDialog, {
                    hawbId: parseInt(hawb_data.hawb_id),
                    hawbNumber: hawb_data.hawb_number,
                    agentType: 'delivery',
                    onSubmit: function(values){
                        me.$refs.deliveries_table.reRequestData();
                    }
                },{ 
                    height: 'auto',
                    scrollable: true,
                    adaptive: true
                });
            },
            reportOsd: function(hawb_data){
                var me = this;
                this.$modal.show(ReportOsdDialog, {
                    hawbId: parseInt(hawb_data.hawb_id),
                    agentType: "delivery",
                    hawbNumber: hawb_data.hawb_number,
                    onSubmit: function(values){
                        me.$refs.deliveries_table.reRequestData();
                    }
                },{ 
                    height: 'auto',
                    scrollable: true,
                    adaptive: true
                });
            },
			summary: function(hawb_data){
				var me = this;
                
                me.$store.dispatch('app/overlayMessage', {
                    message: 'Fetching shipment details, please wait...',
                    spinner: true
                });
                axios.get("/deliveries/hawb_details/"+hawb_data.hawb_id).then(function(response){
                    me.$store.dispatch('app/hideOverlayMessage');
                    var data = response.data.hawb;
                    
                    me.$modal.show(SummaryDialog, {
                        hawbId: parseInt(data.hawb_id),
                        hawbNumber: data.hawb_number,
                        agentType: "delivery", 
                        due_date: moment(data.due_date).format('MM/D/YYYY'),
                        pieces: data.pieces ? parseInt(data.pieces) : 0,
                        weight: data.weight ? parseInt(data.weight) : 0,
                        pallets: data.pallets ? parseInt(data.pallets) : 0,
                        delivery_location: data.delivery_location,
                        consignee_name: data.consignee_name,
                        consignee_address: data.consignee_address,
                        consignee_city: data.consignee_city,
                        consignee_zip: data.consignee_zip,
                        consignee_state: data.consignee_state,
                        pos: data.pos,
                        apptnotes: data.apptnotes,
                        delivery_instruction: data.delivery_instruction,
                        showLess: (data.pos.length >15)? true:false 
                    },{ 
                        height: 'auto',
                        scrollable: true,
                        adaptive: true
                    });
                }).catch(function(){
                    me.$store.dispatch('app/hideOverlayMessage');
                });
			}
        }
    }
</script>

<template>
        <button v-on:click="loginAs" type="button" class="btn btn-sm btn-secondary" title="Login as this user">
            <i class="fas fa-sign-in-alt"></i>
        </button>
</template>
<script>
    import Dialog from './DialogModal';
    export default {
        props: {
            userId: {
                type: [String, Number],
                default: 0
            }
        },
        methods: {
            loginAs: function()
            {
                var me = this;
                
                me.$store.dispatch('app/overlayMessage', {
                    message: 'Switching user, please wait...', 
                    spinner: true 
                });
                
                me.$store.dispatch('user/loginAs', me.userId).then(() => {
                    this.$store.dispatch('app/hideOverlayMessage');
                    me.$emit("user-switched");
                    me.$router.push({ name: 'home' });
                }, (err) => {
                    me.$store.dispatch('app/hideOverlayMessage');
                    me.$modal.show(Dialog, {
                        title: "Login As Failed",
                        message: '<div class="text-center">'+err+'</div>',
                        type: 'danger',
                        buttons: [{
                            label: "OK",
                            align: "right",
                            type: "danger",
                            action: function(dialog){
                                dialog.$emit("close");
                            }
                        }]
                    },{
                        height: 'auto',
                        adaptive: true
                    });
                });
            }
        }
    }
</script>
<template>
    <div class="btn-group" role="group" aria-label="Basic example">
        <button v-if="showCreate" v-on:click="createUser" type="button" class="btn btn-sm btn-outline-secondary" :disabled="disableCreate">
            <i class="fas fa-user-plus"></i> Add
        </button>
        <button v-if="showEdit" v-on:click="editUser" type="button" class="btn btn-sm btn-outline-secondary" :disabled="disableEdit">
            <i class="fas fa-user-edit"></i> Edit
        </button>
        <button v-if="showDelete" v-on:click="deleteUser" type="button" class="btn btn-sm btn-outline-secondary" :disabled="disableDelete">
            <i class="fas fa-user-slash"></i> Delete
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            userId: { type: [Number, String], default: 0 },
            userName: { type: String, default: '' },
            showEdit: { type: Boolean, default: true },
            showDelete: { type: Boolean, default: true },
            showCreate: { type: Boolean, default: true },
            disableEdit: { type: Boolean, default: false },
            disableDelete: { type: Boolean, default: false },
            disableCreate: { type: Boolean, default: false }
        },
        methods: {
            createUser: function()
            {
                this.$emit("create-user", parseInt(this.userId));
            },
            editUser: function()
            {
                this.$emit("edit-user", parseInt(this.userId));
            },
            deleteUser: function()
            {
                this.$emit("delete-user", parseInt(this.userId), this.userName);
            }
        }
    }
</script>
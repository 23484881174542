<template>
<span>{{ display }}</span>
</template>
<script>
export default {
    props: {
        locale: {
            type: String,
            default: '',
        },
        options: {
            type: Object,
            default: function(){ return {}; }
        },
        data: {
            type: [String, Number, Boolean, Array, Object],
            default: 'No'
        },
        row: Object
    },
    computed: {
        display: function()
        {
            return new Intl.NumberFormat(this.locale, this.options).format(this.data);
        }
    }
}
</script>
<template>
<span>{{ display }}</span>
</template>
<script>
window.moment = require('moment');
export default {
    props: {
        outputFormat: {
            type: String,
            default: 'L LT'
        },
        inputFormat: {
            type: String,
            default: ''
        },
        data: {
            type: [String, Number, Boolean, Array, Object],
            default: ''
        },
        row: Object
    },
    computed: {
        display: function()
        {
            var ret = "";
            if(this.data && this.inputFormat)
            {
                var ret = moment(this.data, this.inputFormat);
            }
            else if(this.data)
            {
                var ret = moment(this.data);
            }
            
            if(!ret)
            {
                return "";
            }
            
            if(ret.isValid())
            {
                return ret.format(this.outputFormat);
            }
            else
            {
                return "INVALID DATE";
            }
        }
    }
}
</script>
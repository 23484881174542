<template>
    <div class="btn-group" role="group" aria-label="Basic example">
        <a 
            :href="'/deliveries/receipt/pdf/' + hawbData.hawb_id" 
            type="button" 
            class="btn btn-sm btn-outline-secondary" 
            data-toggle="tooltip" 
            data-placement="top" 
            title="Delivery Receipt PDF"
        >
            <i class="fas fa-print"></i>
        </a>
        <button 
            v-on:click="confirmDelivery" 
            type="button" 
            class="btn btn-sm btn-outline-secondary" 
            :class="{ 'text-success': (deliveryConfirmed || deliveryProcessing) }" 
            v-bind:disabled="deliveryConfirmed || deliveryProcessing" 
            data-toggle="tooltip" 
            data-placement="top" 
            :title="confirmTitle"
        >
            <i class="fas fa-check-circle"></i>
        </button>
        <button 
            v-if="!isDocumentsAttached"
            v-on:click="attachDocument" 
            type="button" 
            class="btn btn-sm btn-outline-secondary" 
            data-toggle="tooltip" 
            data-placement="top" 
            :title="'Attach Document'"
        >
            <i class="fas fa-paperclip"></i>
        </button>
        <button 
            v-if="isDocumentsAttached"
            v-on:click="viewAttachments" 
            type="button" 
            class="btn btn-sm text-success btn-outline-secondary" 
            data-toggle="tooltip" 
            data-placement="top" 
            :title="'View and Attach Documents'"
        >
            <i class="fas fa-paperclip"></i>
        </button>
        <button 
            v-on:click="requestAuth" 
            type="button" 
            class="btn btn-sm btn-outline-secondary" 
            data-toggle="tooltip" 
            data-placement="top" 
            title="Request Authorization"
        >
            <i class="fas fa-comment"></i>
        </button>
        <button 
            v-on:click="reportOsd" 
            type="button" 
            class="btn btn-sm btn-outline-secondary" 
            data-toggle="tooltip" 
            data-placement="top" 
            title="Report OS&amp;D or Refusal"
        >
            <i class="fas fa-flag"></i>
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            hawbData: { 
                type: Object, 
                default: function(){ return {
                    hawb_id: 0
                }
            }}
        },
        computed: {
            deliveryProcessing: function(){
                return this.hawbData.status.toLowerCase() == "processing" ? true : false;
            },
            deliveryConfirmed: function(){
                return this.hawbData.status.toLowerCase() == "delivered" ? true : false;
            },
            confirmTitle: function(){
                return this.hawbData.status.toLowerCase() == "delivered" ? "Delivery has been confirmed" : "Confirm Delivery";
            },
            isDocumentsAttached: function(){
                return this.hawbData.attachments.length > 0 ? true : false;
            }
        },
        methods: {
            confirmDelivery: function()
            {
                this.$emit("delivery-confirm", this.hawbData);
            },
            attachDocument: function()
            {
                this.$emit("delivery-attach-doc", this.hawbData);
            },
            viewAttachments: function()
            {
                this.$emit("delivery-view-doc", this.hawbData);
            },
            requestAuth: function()
            {
                this.$emit("delivery-request-auth", this.hawbData);
            },
            reportOsd: function()
            {
                this.$emit("delivery-report-osd", this.hawbData);
            }
        }
    }
</script>
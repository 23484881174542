<template>
<td :data-title="columnTitle">
    <small><component :is="renderComponent" v-bind="renderComponentProps" v-bind:data="data" v-bind:row="rowData" v-on="$listeners"></component></small>
</td>
</template>
<script>
import DRenderHtml from './Render/Html';
import DRenderYesNo from './Render/YesNo';
import DRenderMoment from './Render/Moment';
import DRenderLocation from './Render/Location';
export default {
    props: { 
        show: Boolean,
        rowData: Object,
        renderer: String,
        columnTitle: String,
        rendererProps: {
            type: Object,
            default: function(){ return {}; }
        },
        data: {
            type: [String, Number, Boolean, Array, Object],
            default: ''
        }
    },
    computed: {
        renderComponent: function(){
            var renderer = (this.renderer) ? this.renderer : "d-render-html";
            if(this.renderer && this.$options.components["d-render-"+this.renderer])
            {
                renderer = "d-render-"+this.renderer;
            }
            return renderer;
        },
        renderComponentProps: function(){
            var props = {};
            for(var p in this.rendererProps)
            {
                if(typeof this.rendererProps[p] === 'string' && this.rendererProps[p] == '{{data}}')
                {
                    props[p] = this.data;
                }
                else if(typeof this.rendererProps[p] === 'string' && this.rendererProps[p].indexOf('{{row}}') === 0)
                {
                    var vals = {};
                    for(var r in this.rowData)
                    {
                        vals[r] = this.rowData[r];
                    }
                    props[p] = JSON.parse(JSON.stringify(vals));
                }
                else if(typeof this.rendererProps[p] === 'string' && this.rendererProps[p].indexOf('{{row.') === 0)
                {
                    props[p] = this.rowData[this.rendererProps[p].replace('{{row.', '').replace('}}', '')];
                }
                else
                {
                    props[p] = this.rendererProps[p];
                }
            }
            return props;
        }
    },
    components: {
        "d-render-html": DRenderHtml,
        "d-render-yes-no": DRenderYesNo,
        "d-render-moment": DRenderMoment,
        "d-render-location": DRenderLocation,
    }
}
</script>
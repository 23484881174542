<template>
<dialog-modal v-on="$listeners">
    <strong slot="header">User Profile</strong>
    <form slot="body" name="delivery-confirm-form" v-on:submit.prevent>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">First name</label>
                    <div class="col-sm-9">
                        <input v-model="user.first_name" 
                            :class="{ 'is-invalid': submitted && $v.user.first_name.$error, 'form-control':true, 'form-control-sm':true }" />
                        <div v-if="submitted && $v.user.first_name.$error" class="invalid-feedback">
                            <span v-if="!$v.user.first_name.required">Please provide a first name</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Last name</label>
                    <div class="col-sm-9">
                        <input v-model="user.last_name" :class="{ 'form-control':true, 'form-control-sm':true }" />
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Phone</label>
                    <div class="col-sm-9">
                        <input v-model="user.phone"
                            :class="{ 'is-invalid': submitted && $v.user.phone.$error, 'form-control':true, 'form-control-sm':true }" />
                        <div v-if="submitted && $v.user.phone.$error" class="invalid-feedback">
                            <span v-if="!$v.user.phone.required">Please provide a phone number</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Email Address</label>
                    <div class="col-sm-9">
                        <input v-model="user.email"
                            :class="{ 'is-invalid': submitted && $v.user.email.$error, 'form-control':true, 'form-control-sm':true }" />
                        <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                            <span v-if="!$v.user.email.required">Please provide a email address</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <button slot="footer" v-on:click="submit" type="button" class="btn btn-primary btn-sm float-right" v-bind:disabled="isSubmitting">
        <span v-if="isSubmitting">Submitting changes...</span>
        <span v-else>Submit Changes</span>
    </button>
    <button slot="footer" v-on:click="$emit('close')" type="button" class="btn btn-secondary btn-sm float-left" v-bind:disabled="isSubmitting">Cancel</button>
</dialog-modal>
</template>
<script>
import Dialog from './DialogModal';
import { required } from 'vuelidate/lib/validators';
export default {
    data: function(){
        return {
            user: {
                first_name: JSON.parse(JSON.stringify(this.$store.state.user.data.first_name)),
                last_name: JSON.parse(JSON.stringify(this.$store.state.user.data.last_name)),
                phone: JSON.parse(JSON.stringify(this.$store.state.user.data.phone)),
                email: JSON.parse(JSON.stringify(this.$store.state.user.data.email))
            },
            isSubmitting: false,
            submitted: false
        }
    },
    validations: {
        user: {
            first_name: { required },
            email: { required },
            phone: { required }
        }
    },
    methods: {
        submit: function(){
            
            var me = this;
            me.submitted = true;
            me.$v.$touch();
            if(me.$v.$invalid)
            {
                me.$modal.show(Dialog, {
                    title: "Form Invalid",
                    message: "Form is invalid, Please fix and click Submit.",
                    type: 'danger'
                },{
                    height: 'auto',
                    adaptive: true
                });
            }
            else
            {
                me.isSubmitting = true;
                axios.post('/user/updateProfile', qs.stringify(this.user), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    me.isSubmitting = false;
                    if(response.data.success && response.data.data)
                    {
                        me.$store.commit("user/updateUserData", response.data.data)
                        me.$emit("close");
                    }
                    else if(response.data.message)
                    {
                        me.notifyUnsuccessfulSubmit(response.data.message);
                    }
                    else
                    {
                        me.notifyUnsuccessfulSubmit();
                    }
                }, function(error){
                    me.isSubmitting = false;
                    me.notifyUnsuccessfulSubmit();
                });
            }
        },
        notifyUnsuccessfulSubmit: function(message){
            this.$modal.show(Dialog, {
                title: "Form Submit Unsuccessful",
                message: message ? message : '<div class="text-center">Error submitting form data!<br />Please refresh the page and try again.</div>',
                type: 'danger',
                buttons: [{
                    label: "OK",
                    align: "right",
                    type: "danger",
                    action: function(dialog){
                        dialog.$emit("close");
                    }
                }]
            },{
                height: 'auto',
                adaptive: true
            });
        },
    }
}
</script>
<template>
    <regular-page v-bind:pageTitle="'Charges'">
        <div class="row">
            <div class="col-xs-12 w-100">
                <d-table 
                    v-bind:tableId="'table_charges'"
                    v-bind:serverSide="false"
                    v-bind:fetchData="this.fetchTableData"
                    v-bind:paging="true"
                    v-bind:pageLength="25"
                    v-bind:scrollY="'400px'"
                    v-bind:columns="columns"
                    v-bind:rowIdKey="'charge_id'"
                    v-bind:tableClass="{'table-sm':true}"
                    v-on:submit-charge-remark="submitChargeRemark"
                    v-on="$listeners"
                ></d-table>
            </div>
        </div>
    </regular-page>
</template>

<script>
    import DTable from './dtable/Table';
    import ChargeRemarkDialog from './ChargeRemarkDialog';
    export default {
        components: {
            'd-table': DTable
        },
        data: function(){
            return {
                columns: [{ 
                    title: 'Hawb', 
                    data: 'hawb_number', 
                    name: 'hawb_number',
                    orderable: true
                },{ 
                    title: 'Date', 
                    data: 'item_date', 
                    name: 'item_date',
                    orderable: true
                },{ 
                    title: 'Location', 
                    data: 'location_name', 
                    name: 'location_name',
                    orderable: true,
                    renderer: 'location',
                    rendererProps: {
                        name: '{{data}}',
                        address: '{{row.location_address}}'
                    }
                },{ 
                    title: 'Type', 
                    data: 'type', 
                    name: 'type',
                    orderable: true
                },{ 
                    title: 'Pieces', 
                    data: 'pieces', 
                    name: 'pieces',
                    orderable: true,
                    renderer: 'number',
                    rendererProps: {
                        locale: 'en-US'
                    }
                },{ 
                    title: 'Weight', 
                    data: 'weight', 
                    name: 'weight',
                    orderable: true,
                    renderer: 'number',
                    rendererProps: {
                        locale: 'en-US'
                    }
                },{ 
                    title: 'Actions', 
                    data: 'hawb_id', 
                    name: 'actions',
                    renderer: 'charge-actions',
                    rendererProps: {
                        chargeData: "{{row}}"
                    }
                }]
            }
        },
        methods: {
            submitChargeRemark: function(charge_data){
                var me = this;
                me.$modal.show(ChargeRemarkDialog, {
                    hawbId: parseInt(charge_data.hawb_id),
                    hawbNumber: charge_data.hawb_number,
                    chargeId: parseInt(charge_data.charge_id),
                    chargeType: charge_data.type
                },{ 
                    height: 'auto',
                    scrollable: true,
                    adaptive: true
                });
            },
            fetchTableData: function(callback){
                axios.post("/charges/list", [], {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    callback(response.data.data);
                });
            }
        }
    }
</script>
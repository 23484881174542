<style>
    .filters-dialog .datepicker-calendar {
        z-index: 3000
    }
</style>
<template>
<dialog-modal v-on="$listeners">
    <strong slot="header" class="align-middle">{{ dialogTitle }}</strong>

    <form slot="body" name="filters-dialog" v-on:submit.prevent>
        <div v-for="filter in filters" v-bind:key="filter.name" class="form-group row">
            <label class="col-sm-4 col-form-label text-md-right" v-bind:for="filter.name">{{ filter.label }}</label>
            <div class="col-sm-8">
                <component 
                    v-bind:is="filter.type"
                    v-on:filter-applied="setFilterApplied(filter.name, true)" 
                    v-on:filter-unapplied="setFilterApplied(filter.name, false)" 
                    v-model="$data.filter_values[filter.name].result" 
                    v-bind="filter.props"
                ></component>
            </div>
        </div>
    </form>
    
    <button slot="footer" v-on:click="clearFilters" type="button" class="btn btn-secondary btn-sm">Clear</button>
    <button slot="footer" v-on:click="applyFilters" type="button" class="btn btn-primary btn-sm float-right">Apply</button>
</dialog-modal>
</template>
<script>
export default {
    props: {
        dialogTitle: {
            type: String,
            default: 'Filters'
        },
        filters: {
            type: Array
        },
        onFilterUpdate: {
            type: Function,
            default: function(){}
        },
        onFilterClear: {
            type: Function,
            default: function(){}
        }
    },
    data: function(){
        
        var filters_data = {};
        for(var f in this.filters)
        {
            filters_data[this.filters[f].name] = {
                result: (this.filters[f].result) ? this.filters[f].result : '',
                applied: false
            };
        }
        return {
            filter_values: filters_data,
        };
    },
    methods: {
        setFilterApplied: function(filter_name, applied)
        {
            this.filter_values[filter_name].applied = applied;
        },
        getFilterValues: function()
        {
            return this.$data.filter_values;
        },
        clearFilters: function()
        {
            this.$emit('close');
            for(var f in this.filters)
            {
                this.filter_values[this.filters[f].name].result = '';
                this.filter_values[this.filters[f].name].applied = false;
            }
            this.onFilterClear();
        },
        applyFilters: function()
        {
            this.$emit('close');
            this.onFilterUpdate(this.getFilterValues());
        }
    }
}
</script>
// initial state
const state = {
    git_branch: window.app.git_branch,
    environment: window.app.environment,
    overlayed_message: {
        spinner: true,
        message: '',
        active: false
    }
}

// getters
const getters = {
    
}

// actions
const actions = {
    overlayMessage(context, payload){
        context.commit('setSpinner', payload.spinner);
        context.commit('setMessage', payload.message);
        if(payload.message || payload.spinner)
        {
            context.commit('setActive', true);
        }
        else
        {
            context.commit('setActive', false);
        }
    },
    hideOverlayMessage(context){
        context.commit('setActive', false);
        context.commit('setSpinner', false);
        context.commit('setMessage', '');
    }
}

// mutations
const mutations = {
    setMessage(state, message){
        state['overlayed_message'].message = message;
    },
    setSpinner(state, spinner){
        state['overlayed_message'].spinner = spinner ? true : false;
    },
    setActive(state, active){
        state['overlayed_message'].active = active ? true : false;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
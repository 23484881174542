<style>
    .v--modal-box {
        overflow: visible !important;
    }
</style>
<template>
<div :class="card_class" v-on="$listeners">
    <div :class="card_header_class">
        <div class="row no-gutters">
            <div class="col-11">
                <strong v-if="title" v-html="title"></strong>
                <slot name="header"></slot>
            </div>
            <div class="col-1 text-right">
                <button v-if="closeable" v-on:click="$emit('close')" type="button" :class="close_btn_class">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div v-if="message" v-html="message"></div>
        <slot name="body"></slot>
        <component v-if="component" ref="component" :is="component" v-bind="componentProps" v-on="$listeners"></component>
    </div>
    <div v-if="$slots.footer || buttons.length > 0" class="card-footer text-muted">
        <slot name="footer"></slot>
        <button 
        v-for="(button, index) in buttons" 
        :id="button.id"
        v-bind:key="(button.id) ? button.id : 'msg_dlg_'+index" 
        slot="footer" 
        type="button" 
        v-on:click="clickButton(button.action)" 
        v-html="button.label"
        :class="buttonClass(button.type, button.size, button.align)"></button>
    </div>
</div>
</template>
<script>
export default {
    props: {
        type: { type: String, default: 'default' }, //primary, secondary, success, danger, warning, info, dark
        title: { type: String, default: '' },
        message: { type: String, default: '' },
        closeable: { type: Boolean, default: true },
        component: { type: String, default: '' },
        componentProps: {
            type: Object,
            default: function(){ return {}; }
        },
        buttons: { type: Array, default: function(){ 
            return [];
        }}
    },
    computed: {
        card_class: function(){
            switch(this.type){
                case 'primary': return { 'card':true, 'm-0':true, 'border-primary':true }; break;
                case 'secondary': return { 'card':true, 'm-0':true, 'border-secondary':true }; break;
                case 'success': return { 'card':true, 'm-0':true, 'border-success':true }; break;
                case 'danger': return { 'card':true, 'm-0':true, 'border-danger':true }; break;
                case 'warning': return { 'card':true, 'm-0':true, 'border-warning':true }; break;
                case 'info': return { 'card':true, 'm-0':true, 'border-info':true }; break;
                case 'dark': return { 'card':true, 'm-0':true, 'border-dark':true }; break;
                default: return { 'card':true, 'm-0':true };
            }
        },
        card_header_class: function(){
            switch(this.type){
                case 'primary': return { 'card-header':true, 'text-white':true, 'bg-primary':true }; break;
                case 'secondary': return { 'card-header':true, 'text-white':true, 'bg-secondary':true }; break;
                case 'success': return { 'card-header':true, 'text-white':true, 'bg-success':true }; break;
                case 'danger': return { 'card-header':true, 'text-white':true, 'bg-danger':true }; break;
                case 'warning': return { 'card-header':true, 'bg-warning':true }; break;
                case 'info': return { 'card-header':true, 'text-white':true, 'bg-info':true }; break;
                case 'dark': return { 'card-header':true, 'text-white':true, 'bg-dark':true }; break;
                default: return { 'card-header':true };
            }
        },
        close_btn_class: function(){
            switch(this.type){
                case 'primary': return { 'btn':true, 'btn-primary':true, 'btn-sm':true }; break;
                case 'secondary': return { 'btn':true, 'btn-secondary':true, 'btn-sm':true }; break;
                case 'success': return { 'btn':true, 'btn-success':true, 'btn-sm':true }; break;
                case 'danger': return { 'btn':true, 'btn-danger':true, 'btn-sm':true }; break;
                case 'warning': return { 'btn':true, 'btn-warning':true, 'btn-sm':true }; break;
                case 'info': return { 'btn':true, 'btn-info':true, 'btn-sm':true }; break;
                case 'dark': return { 'btn':true, 'btn-dark':true, 'btn-sm':true }; break;
                default: return { 'btn':true, 'btn-link':true, 'btn-lg':true, 'p-0':true };
            }
        }
    },
    methods: {
        clickButton: function(cb){
            if(cb)
            {
                cb(this, (this.component ? this.$refs.component : false));
            }
        },
        buttonClass: function(type, size, alignment)
        {
            var class_props = { 'btn': true };
            
            switch(type){
                case 'primary': class_props['btn-primary'] = true;  break;
                case 'success': class_props['btn-success'] = true;  break;
                case 'danger': class_props['btn-danger'] = true;  break;
                case 'warning': class_props['btn-warning'] = true;  break;
                case 'info': class_props['btn-info'] = true;  break;
                case 'dark': class_props['btn-dark'] = true;  break;
                case 'outline-primary': class_props['btn-outline-primary'] = true;  break;
                case 'outline-success': class_props['btn-outline-success'] = true;  break;
                case 'outline-danger': class_props['btn-outline-danger'] = true;  break;
                case 'outline-warning': class_props['btn-outline-warning'] = true;  break;
                case 'outline-info': class_props['btn-outline-info'] = true;  break;
                case 'outline-dark': class_props['btn-outline-dark'] = true;  break;
                case 'link': class_props['btn-link'] = true;  break;
                default: class_props['btn-secondary'] = true;  break;
            }
            
            switch(size)
            {
                case 'lg': class_props['btn-lg'] = true; break;
                case 'sm': class_props['btn-sm'] = true; break;
            }
            
            if(alignment == "right")
            {
                class_props['float-right'] = true;
            }
            
            return class_props;
        }
    }
}
</script>
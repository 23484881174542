<template>
<div>
    <hr>
    <div class="text-center mb-4">
        <small>
            Copyright &copy; <a href="https://www.pacific-logistics.com">Pacific Logistics Corp</a><br />
            <em>v {{ version }}</em> | <a href="#" v-on:click.prevent="showTerms">Terms</a> | <a href="#" v-on:click.prevent="showPrivacyPolicy">Privacy Policy</a> | <a href="#" v-on:click.prevent="showProfile">User Profile</a>
        </small>
    </div>
</div>
</template>
<script>
import UserProfileDialog from '../UserProfileDialog';
import TermsDialog from '../TermsDialog';
import PrivacyPolicyDialog from '../PrivacyPolicyDialog';
export default {
    data: function(){
        return {
            version: process.env.MIX_APP_VERSION,
        }
    },
    methods: {
        showProfile: function()
        {
            var me = this;
            this.$modal.show(UserProfileDialog, {},{ 
                height: 'auto',
                scrollable: true,
                adaptive: true
            });
        },
        showTerms: function()
        {
            var me = this;
            this.$modal.show(TermsDialog, {},{ 
                height: 'auto',
                scrollable: true,
                width: 800,
                adaptive: true
            });
        },
        showPrivacyPolicy: function()
        {
                        var me = this;
            this.$modal.show(PrivacyPolicyDialog, {},{ 
                height: 'auto',
                scrollable: true,
                width: 800,
                adaptive: true
            });
        }
    }
}
</script>
<template>
    <span>
        <button v-on:click="$emit('show-filters')" type="button" class="btn btn-secondary btn-sm">
            Filters <span v-if="totalFiltersApplied" class="badge badge-light">{{ totalFiltersApplied }}</span>
        </button>
    </span>
</template>
<script>
export default {
    props: { 
        totalFiltersApplied: {
            type: Number,
            default: 0
        }
    }
}
</script>
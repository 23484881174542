<template>
    <div ref="range_toggle_container">
        <div class="btn-group btn-group-toggle d-flex">
            <button 
                v-for="(range, rangeIndex) in ranges" 
                class="btn btn-sm btn-secondary"
                :class="{'active':(isInRange(rangeIndex))?true:false}"
                v-bind:data-placement="'top'"
                v-bind:title="(range.tooltip) ? range.tooltip : ''"
                v-bind:data-toggle="(range.tooltip) ? 'tooltip' : ''"
                type="button" 
                v-on:click="onToggle(rangeIndex)"
                autocomplete="off" 
                aria-pressed="false" >
            <span v-html="range.label"></span>
            </button>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            value: { type: String, default: '' },
            ranges: { type: Array, default: function(){ return []; }}
        },
        data: function(){
            return {
                dirty: false
            };
        },
        watch: {
            value: function(){
                this.notifyApplied();
            }
        },
        methods: {
            onToggle: function(rangeIndex)
            {
                if(this.ranges[rangeIndex] != this.getActiveRange())
                {
                    this.dirty = true;
                    
                    if(this.ranges[rangeIndex].startAtDay === undefined && this.ranges[rangeIndex].endAtDay === undefined)
                    {
                        this.$emit('input', "");
                    }
                    else
                    {
                        var startAtDay = this.ranges[rangeIndex].startAtDay;
                        var endAtDay = this.ranges[rangeIndex].endAtDay;
                        this.$emit('input', JSON.stringify(this.calculateRangeDates(startAtDay, endAtDay)));
                    }
                }
            },
            isInRange(rangeIndex)
            {
                return this.ranges[rangeIndex] == this.getActiveRange() ? true : false;
            },
            calculateRangeDates: function(startAtDay, endAtDay)
            {
                var startAt = '';
                var endAt = '';

                if(startAtDay >= 0)
                {
                    startAt = moment().add(startAtDay, 'days').format("YYYY-MM-DD");
                    
                }
                else if(startAtDay < 0)
                {
                    startAt = moment().subtract(startAtDay, 'days').format("YYYY-MM-DD");
                }
                
                if(endAtDay >= 0)
                {
                    endAt = moment().add(endAtDay, 'days').format("YYYY-MM-DD");
                    
                }
                else if(endAtDay < 0)
                {
                    endAt = moment().subtract(endAtDay, 'days').format("YYYY-MM-DD");
                }
                
                return {
                    start: startAt,
                    end: endAt
                };
            },
            getActiveRange: function()
            {
                for(var r in this.ranges)
                {
                    var rangeDates = this.calculateRangeDates(this.ranges[r].startAtDay, this.ranges[r].endAtDay);
                    var curValue = this.value ? this.value : JSON.stringify({start:"",end:""});
                    if(JSON.stringify({start:rangeDates.start,end:rangeDates.end}) == curValue)
                    {
                        return this.ranges[r];
                    }
                }
                return false;
            },
            getDateValue: function()
            {
                var activeRange = this.getActiveRange();
                if(activeRange)
                {
                    return this.calculateRangeDates(activeRange.startAtDay, activeRange.endAtDay);
                }
                
                return "";
            },
            notifyApplied: function()
            {
                if(this.dirty)
                {
                    var activeRange = this.getActiveRange();
                    if(activeRange && !activeRange.active){
                        this.$emit('filter-applied');
                    } else {
                        this.$emit('filter-unapplied');
                    }
                }
                this.dirty = false;
            }
        },
        created() {
            this.notifyApplied();
            this.$emit('input', JSON.stringify(this.getDateValue()));
        }
    }
</script>
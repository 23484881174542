<template>
<dialog-modal v-on="$listeners">
    <strong slot="header">User <small v-if="$data.user['id']" class="pl-2 text-muted">ID: <em>{{ $data.user["id"] }}</em></small></strong>
    <form slot="body" ref="user_form" name="user-form" v-on:submit.prevent>
        <div v-if="isProcessing" class="text-center">
            <spinner></spinner>
        </div>
        <div v-if="!isProcessing" class="row">
            <div class="col-sm-6">
                <strong>User</strong><hr class="mt-1 mb-2" />
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">First Name</label>
                    <div class="col-sm-8">
                        <input 
                            :class="{ 'is-invalid': submitted && $v.user.first_name.$error, 'form-control':true, 'form-control-sm':true }"
                            v-model="$data.user['first_name']" />
                        <div v-if="submitted && $v.user.first_name.$error" class="invalid-feedback">
                            <span v-if="!$v.user.first_name.required">Please provide a first name</span>
                            <span v-if="!$v.user.first_name.minLength">Mininimum 2 characters.</span>
                            <span v-if="!$v.user.first_name.maxLength">Maximum 50 characters.</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Last Name</label>
                    <div class="col-sm-8">
                        <input :class="{ 'is-invalid': submitted && $v.user.last_name.$error, 'form-control':true, 'form-control-sm':true }"
                         v-model="$data.user['last_name']" />
                        <div v-if="submitted && $v.user.last_name.$error"  class="invalid-feedback">
                            <span v-if="!$v.user.last_name.required">Please provide a last name</span>
                            <span v-if="!$v.user.last_name.minLength">Mininimum 2 characters.</span>
                            <span v-if="!$v.user.last_name.maxLength">Maximum 50 characters.</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Phone</label>
                    <div class="col-sm-8">
                        <input :class="{ 'is-invalid': submitted && $v.user.phone.$error, 'form-control':true, 'form-control-sm':true }"
                         v-model="$data.user['phone']" />
                        <div v-if="submitted && $v.user.phone.$error" class="invalid-feedback">
                            <span v-if="!$v.user.phone.minLength">Please provide a phone number.</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Email</label>
                    <div class="col-sm-8">
                        <input 
                            :class="{ 'is-invalid': submitted && $v.user.email.$error, 'form-control':true, 'form-control-sm':true }"
                            v-model="$data.user['email']" />
                        <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                            <span v-if="!$v.user.email.required">Please provide an email address</span>
                            <span v-if="!$v.user.email.email">Please provide a proper email address</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <strong>Authentication</strong><hr class="mt-1 mb-2" />
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Username</label>
                    <div class="col-sm-8">
                        <input 
                            :class="{ 'is-invalid': submitted && $v.user.username.$error, 'form-control':true, 'form-control-sm':true }"
                            v-model="$data.user['username']" />
                        <div v-if="submitted && $v.user.username.$error" class="invalid-feedback">
                            <span v-if="!$v.user.username.required">Please provide a username<br /></span>
                            <span v-if="!$v.user.username.minLength">Minimum 3 characters.<br /></span>
                            <span v-if="!$v.user.username.maxLength">Maximum 25 characters.<br /></span>
                            <span v-if="!$v.user.username.validateUsername">Please enter only letters, numbers, or underscores.<br /></span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Password</label>
                    <div class="col-sm-8">
                        <input type="password" placeholder="Leave empty to not change" 
                            :class="{ 'is-invalid': submitted && $v.user.password.$error, 'form-control':true, 'form-control-sm':true }"
                            v-model="$data.user['password']" area-describedby="passwordHelp" />
                        <div v-if="submitted && $v.user.password.$error && !$data.user.has_password" class="invalid-feedback">
                            <span v-if="!$v.user.password.passRequired">Please provide a password</span>
                            <span v-if="!$v.user.password.minLength">Password must be a minimum of 8 characters long.</span>
                            <ul v-if="!$v.user.password.validateLcase || !$v.user.password.validateUcase || !$v.user.password.validateNumber 
                                || !$v.user.password.validateSpecChar" class="password-list">
                                <li>Please provide at least...</li>
                                <li v-if="!$v.user.password.validateLcase" class="password-list-validate">1 lower case letter</li>
                                <li v-if="!$v.user.password.validateUcase" class="password-list-validate">1 upper case letter</li>
                                <li v-if="!$v.user.password.validateNumber" class="password-list-validate">1 number</li>
                                <li v-if="!$v.user.password.validateSpecChar" class="password-list-validate">1 special character (@,&amp;, $)</li>
                            </ul>
                        </div>
                        <small v-if="!$v.user.password.$error" id="passwordHelp" class="form-text text-muted">
                            Must be a minimum of 8 characters long and contain at least: 
                            <ul class="pl-3"><li>1 uppercase letter</li><li>1 lowercase letter</li><li>1 special character (@,&amp;, $)</li><li>1 number</li></ul>
                        </small>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Enabled</label>
                    <div class="col-sm-8">
                        <input type="checkbox" v-model="$data.user['enabled']" />
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <strong>Role(s)</strong>
                <hr class="mt-1 mb-2" />
                <role-selection class="mb-3" v-on:role-selection-updated="updateRoles" v-bind:roles="$data.user.roles"></role-selection>
                <div v-if="submitted && $v.user.roles.$error" style="font-size: 80%; color: #e3342f;">
                    <span v-if="!$v.user.roles.minRoles">Please select at least one role</span>
                </div>
            </div>
            <div class="col-sm-12">
                <strong>Agent Association(s)</strong>
                <hr class="mt-1 mb-2" />
                <button v-on:click="addAgent" type="button" class="btn btn-sm btn-outline-secondary mb-2">
                    <i class="fas fa-plus-square"></i> Associate <span v-if="$data.user.agents.length > 0">Another</span> Agent
                </button>
                <agents-list v-bind:agents="$data.user.agents" v-on:remove-agent="disassociateAgent" v-bind:allowRemove="true"></agents-list>
            </div>
        </div>
    </form>
    <button v-if="!isProcessing" slot="footer" type="button" v-on:click="submit" class="btn btn-primary btn-sm float-right">Submit</button>
    <button v-if="!isProcessing" slot="footer" v-on:click="$emit('close')" type="button" class="btn btn-secondary btn-sm float-left">Cancel</button>
</dialog-modal>
</template>
<script>
import Dialog from './DialogModal';
import AgentSearchDialog from './agent/AgentSearchDialog';
import { required, minLength, maxLength, email, alphaNum } from 'vuelidate/lib/validators';
export default {
    props: {
        userId: { type: Number, default: 0 },
        onSubmit: { type: Function, default: function(){} }
    },
    data: function(){
        return {
            user: this.getDefaultUserValues(),
            is_processing: true,
            submitted: false,
        };
    },
    validations: {
        user: {
            first_name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50)
            },
            last_name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50)
            },
            email: {
                required,
                email,
            },
            phone: {
                minLength: minLength(4)
            },
            username: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(25),
                validateUsername: function(username){
                    if(username.match(/^[a-zA-Z0-9-_]+$/)){
                        return true;
                    }
                    return false;
                },
            },
            password: {
                minLength: minLength(8),
                passRequired: function(){ 
                    if(this.$data.user.has_password)
                    {
                        return true;
                    }
                    else if(!this.$data.user.password)
                    {
                        return false;
                    }
                    return true;
                },
                validateLcase: function(password){
                    if(this.$data.user.has_password)
                    {
                        return true;
                    }

                    if(password.match(/[a-z]/g)){
                        return true;
                    }
                    return false;

                },
                validateUcase: function(password){
                    
                    if(this.$data.user.has_password)
                    {
                        return true;
                    }

                    if(password.match(/[A-Z]/g)){
                        return true;
                    }
                    return false;
                },
                validateSpecChar: function(password){

                    if(this.$data.user.has_password)
                    {
                        return true;
                    }

                    if(password.match(/\W|_/g)){
                        return true;  
                    }
                    return false;
                },
                validateNumber: function(password){

                    if(this.$data.user.has_password)
                    {
                        return true;
                    }
                    
                    if(password.match(/[0-9]/g)){
                        return true;
                    }
                    return false;
                },
            },
            roles: { 
                minRoles: function(){ 
                    return (this.$data.user.roles.length > 0) ? true : false; 
                }
            }
        }
    },
    computed: {
        isProcessing: function(){
            return this.$data.is_processing;
        }
    },
    methods: {
        submit: function(){
            var me = this;
            this.submitted = true;
            this.$v.$touch();
            if(this.$v.$invalid)
            {
                this.$modal.show(Dialog, {
                    title: "Form Invalid",
                    message: "Form is invalid, Please fix and click Submit.",
                    type: 'danger'
                },{
                    height: 'auto',
                    adaptive: true
                });
            }
            else
            {
                this.is_processing = true;
                axios.post('/user/save', qs.stringify(this.getSaveableData()), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    me.is_processing = false;
                    if(response.data.success && response.data.data)
                    {
                        me.$emit("user_saved", { 
                            old_user_data: me.$data.user, 
                            new_user_data: response.data.data 
                        });
                        
                        me.$props.onSubmit({
                            old_user_data: me.$data.user, 
                            new_user_data: response.data.data 
                        });
                        
                        me.$modal.show(Dialog, {
                            title: "Form Submitted",
                            message: '<div class="text-center">User successfully saved</div>',
                            type: 'success',
                            buttons: [{
                                label: "OK",
                                align: "right",
                                type: "success",
                                action: function(dialog){
                                    dialog.$emit("close");
                                }
                            }]
                        },{
                            height: 'auto',
                            adaptive: true
                        });
                        
                        me.$emit("close");
                    }
                    else if(response.data.message)
                    {
                        me.notifyUnsuccessfulSubmit(response.data.message);
                    }
                    else
                    {
                        me.notifyUnsuccessfulSubmit();
                    }
                }, function(error){
                    me.is_processing = false;
                    me.notifyUnsuccessfulSubmit();
                }); 
            }
        },
        notifyUnsuccessfulSubmit: function(message){
            this.$modal.show(Dialog, {
                title: "Form Submit Unsuccessful",
                message: message ? message : '<div class="text-center">Error submitting form data!<br />Please refresh the page and try again.</div>',
                type: 'danger',
                buttons: [{
                    label: "OK",
                    align: "right",
                    type: "danger",
                    action: function(dialog){
                        dialog.$emit("close");
                    }
                }]
            },{
                height: 'auto',
                adaptive: true
            });
        },
        getDefaultUserValues: function(){
            return {
                id: 0,
                first_name: '',
                last_name: '',
                username: '',
                password: '',
                phone: '',
                email: '',
                terms_agreed: false,
                enabled: 1,
                login_total: 0,
                bill_to_ids: '',
                email_updated: '',
                email_verified_at: '',
                terms_agreed_on: '',
                last_html_request: '',
                has_password: false,
                permission_overwrite: 0,
                menu_overwrite: 0,
                remember_token: '',
                created_at: '',
                updated_at: '',
                agents: [],
                roles: []
            }
        },
        isPasswordRequired: function(){
            return this.$data.user.has_password ? true : false;
        },
        getSaveableData: function(){
            
            //General user data
            var saveable = {
                id: this.$data.user.id,
                first_name: this.$data.user.first_name,
                last_name: this.$data.user.last_name,
                phone: this.$data.user.phone,
                email: this.$data.user.email,
                username: this.$data.user.username,
                enabled: this.$data.user.enabled ? 1 : 0,
                roles: [],
                agents: [],
                has_password: this.$data.user.has_password,
            };

            //Password
            if(this.$data.user.password)
            {
                saveable.password = this.$data.user.password;
            }
            
            //Roles
            for(var a in this.$data.user.roles)
            {
                saveable.roles.push(this.$data.user.roles[a].id);
            }
            saveable.roles = saveable.roles.join(",");
            
            //Agents
            for(var a in this.$data.user.agents)
            {
                saveable.agents.push(this.$data.user.agents[a].id);
            }
            saveable.agents = saveable.agents.join(",");
            
            return saveable;
        },
        associateAgent: function(agent){
            this.$data.user.agents.push(agent);
        },
        disassociateAgent: function(agent){
            this.$data.user.agents = this.$data.user.agents.filter(function(a){
                return (a.id != agent.id);
            });
        },
        updateRoles: function(updated_roles){
            this.$data.user.roles= updated_roles;
        },
        addAgent: function(){
            var me = this;
            this.$modal.show(AgentSearchDialog, {
                onAgentSelect: function(agent){
                    me.associateAgent(agent);
                }
            }, {
                height: 'auto',
                scrollable: true,
                width: 800,
                adaptive: true
            });
        },
        getUser: function(){
            var me = this;
            if(this.userId)
            {
                axios.get('/user/id/'+this.userId, {}, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    if(response.data.data)
                    {
                        for(var k in response.data.data)
                        {
                            if(typeof me.$data.user[k] !== 'undefined')
                            {
                                me.$data.user[k] = response.data.data[k];
                            }
                        }
                    }
                    me.$data.is_processing = false;
                });
            }
            else
            {
                me.$data.is_processing = false;
            }
        }
    },
    mounted: function(){
        var me = this;
        this.getUser();
    }
}
</script>
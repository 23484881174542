<template>
    <div>
        <div v-if="pageTitle">
            <div class="container p-0">
                <div class="d-flex justify-content-around justify-content-lg-between justify-content-xl-between flex-wrap">
                    <h1 class="page-title">{{ pageTitle }}</h1>
                    <slot name="title-bar"></slot>
                </div>
            </div>
            <hr class="mt-2" />
        </div>
        <div class="container">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: { 
        pageTitle: String,
    },
}
</script>
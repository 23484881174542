<template>
<div class="row">
    <div v-if="isProcessing" class="col text-center">
        <i class="fas fa-spinner fa-spin"></i>
    </div>
    <div class="col" v-for="role in roleOptions" v-bind:key="role.id">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="role.selected" v-on:change="selectionChange" v-bind:value="role.id" v-bind:id="'role_'+role.id">
            <label class="form-check-label" v-bind:for="'role_'+role.id">
                {{role.name}}
            </label>
        </div>
    </div>
</div>
</template>
<script>

export default {
    props: {
        roles: { type: Array, default: function(){ return []; }},
        loaded: { type: Boolean, default: false }
    },
    watch: {
        roles: {
            immediate: true,
            handler (new_roles, old_roles)
            {
                this.updateSelection();
            }
        }
    },
    data: function(){
        return {
            is_processing: true,
            roleOptions: []
        };
    },
    computed: {
        isProcessing: function(){
            return this.$data.is_processing;
        }
    },
    methods: {
        updateSelection: function(){
            for(var sel in this.roles)
            {
                for(var avail in this.roleOptions)
                {
                    if(this.roleOptions[avail].id == this.roles[sel].id)
                    {
                        this.roleOptions[avail].selected = true;
                    }
                }
            }
        },
        selectionChange: function(){
            var updated_selection = [];
            for(var avail in this.roleOptions)
            {
                if(this.roleOptions[avail].selected)
                {
                    updated_selection.push({
                        id: this.roleOptions[avail].id,
                        name: this.roleOptions[avail].name
                    });
                }
            }
            this.$emit('role-selection-updated', updated_selection);
        },
        isSelected: function(role_id) {
            var length = this.roles.length;
            for(var i = 0; i < length; i++) {
                if(this.roles[i] == role_id) return true;
            }
            return false;
        }
    },
    mounted: function(){
        var me = this;
        this.$data.is_processing = true;
        axios.get('/roles/list', {}, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(response){
            if(response.data && typeof response.data === 'object')
            {
                var options = [];
                for(var d in response.data)
                {
                    options.push({
                        id: response.data[d].id,
                        name: response.data[d].name,
                        selected: me.isSelected(response.data[d].id)
                    });
                }
                me.$data.roleOptions = options;
            }
            me.updateSelection();
            me.$data.is_processing = false;
        });
    }
}
</script>
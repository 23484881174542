<style>
    .overlayed-message{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9997;
    }
    
    .overlayed-message-modal{
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9998;
    }
    .overlayed-message-body{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
    }
    .overlayed-message.transitioned-in .overlayed-message-body{
        display: block;
    }
    .overlayed-message-body .d-flex{
        width: 100%;
        height: 100%;
    }
</style>
<template>
<transition name="fade" v-on:after-enter="onModalAppeared">
    <div v-if="overlay.active" v-bind:class="{'overlayed-message':true, 'transitioned-in': modalAppeared}">
        <div class="overlayed-message-modal"></div>
        <div class="overlayed-message-body">
            <div class="d-flex align-items-center justify-content-center">
                <span class="border border-secondary rounded p-4 bg-light shadow">
                    <spinner v-if="overlay.spinner" style="font-size: 24px;"></spinner>
                    <span class="align-items-center pl-2" v-if="overlay.message" v-html="overlay.message"></span>
                </span>
            </div>
        </div>
    </div>
</transition>
</template>
<script>
export default {
    data: function(){
        return {
            modalAppeared: false
        }
    },
    computed: {
        overlay: function(){
            return this.$store.state.app.overlayed_message;
        }
    },
    methods: {
        onModalAppeared: function()
        {
            this.modalAppeared = true;
        }
    }
}
</script>
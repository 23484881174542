<template>
  <dialog-modal
  v-on="$listeners"
  >
    <strong slot="header">Menu Item Under: <small>{{ this.parent_identifier }}</small></strong>
    <form slot="body" name="delivery-confirm-form" v-on:submit.prevent>
        <input type="hidden" v-model="menuItem.id" />
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <div class="form-group row">
                    <label class="col-sm-2 col-md-4 col-form-label text-right">Parent Identifier</label>
                    <div class="col-sm-10 col-md-8">
                        <input v-model="menuItem.parent_identifier" class="form-control form-control-sm" disabled />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-md-4 col-form-label text-right">Identifier</label>
                    <div class="col-sm-10 col-md-8">
                        <input v-model="menuItem.identifier" class="form-control form-control-sm" :disabled="menuItem.id > 0" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-md-4 col-form-label text-right">Title</label>
                    <div class="col-sm-10 col-md-8">
                        <input v-model="menuItem.title" class="form-control form-control-sm"  placeholder="Title of page" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-md-4 col-form-label text-right">Label</label>
                    <div class="col-sm-10 col-md-8">
                        <input v-model="menuItem.label" class="form-control form-control-sm"  placeholder="Menu item text" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-md-4 col-form-labe text-right">Component</label>
                    <div class="col-sm-10 col-md-8">
                        <input v-model="menuItem.component" class="form-control form-control-sm"  placeholder="<component-name></component-name>" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-md-4 col-form-label text-right">Description</label>
                    <div class="col-sm-10 col-md-8">
                        <textarea v-model="menuItem.description" class="form-control form-control-sm"  placeholder="Short Description shown as tooltip when moused over" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-md-4 col-form-label text-right">Class</label>
                    <div class="col-sm-10 col-md-8">
                        <input v-model="menuItem.additional_class" class="form-control form-control-sm" placeholder="Classes added to this menu item when rendered" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-md-4 col-form-label text-right">Icon</label>
                    <div class="col-sm-10 col-md-8">
                        <div class="input-group input-group-sm">
                            <input v-model="menuItem.icon" class="form-control form-control-sm" aria-describedby="input-fa-icon" placeholder="Menu item icon" />
                            <div class="input-group-append">
                                <span class="input-group-text" id="input-fa-icon"><i v-bind:class="'fas '+menuItem.icon"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                 <div class="form-group row">
                    <label class="col-sm-2 col-md-4 col-form-label text-right" disabled>Position</label>
                    <div class="col-sm-10 col-md-8">
                        <input v-model="menuItem.position" class="form-control form-control-sm" placeholder="Position" disabled />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-2 col-md-4 text-right">
                        Device(s)
                    </div>
                    <div class="col-sm-10 col-md-8">
                        <div class="form-group row">
                            <div class="form-check form-check-inline"  v-for="item in device" :key="item.name">
                                <input type="checkbox" :id="item.name" v-model="item.checked" class="form-check-input">
                                <label class="form-check-label"><i class="ml-1 mr-1 fa menu-dialog-icon" :class="item.icon"></i>{{ item.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-2 col-md-4 text-right">
                        Roles
                    </div>
                    <div class="col-sm-10 col-md-8">
                        <div class="form-group row">
                            <role-selection class="mb-3"  v-on:role-selection-updated="updateRoles" v-bind:roles="menuItem.roles"></role-selection>
                            <div v-if="submitted && roles.$error" style="font-size: 80%; color: #e3342f;">
                                <span v-if="!roles.minRoles">Please select at least one role</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>
    <button slot="footer" v-on:click="submit" type="button" class="btn btn-primary btn-sm float-right">Submit</button>
    <button slot="footer" v-on:click="$emit('close')" type="button" class="btn btn-secondary btn-sm float-left">Cancel</button>
  </dialog-modal>
</template>
<script>
export default {
    props: {
        onSubmit: { type: Function, default: function(){}},
        id: { type: Number, default: 0 },
        identifier: { type: String, default: '' },
        parent_identifier: { type: String, default: '' },
        label: { type: String, default: '' },
        component: { type: String, default: '' },
        title: { type: String, default: '' },
        description: { type: String, default: '' },
        icon: { type: String, default: '' },
        additional_class: { type: String, default: '' },
        position: { type: Number, default: 0 },
        created_at: { type: String, default: '' },
        modified_at: { type: String, default: '' },
        device: {
            type: Array,
            default: function() {
                return [{
                    name: "desktop",
                    checked: true,
                    icon: 'fa-desktop',
                },{
                    name: "tablet",
                    checked: false,
                    icon: 'fa-tablet',
                },{
                    name: "phone",
                    checked: false,
                    icon: 'fa-mobile'
                }]
            },
        },
        list: { type: Array },
        roles: { type: Array, default: function(){ return []; } }
    },
    data: function(){
        return {
            isProcessing: false,
            submitted: true,
            menuItem: {
                id: this.id,
                identifier: this.identifier,
                parent_identifier: this.parent_identifier,
                title: this.title,
                label: this.label,
                component: this.component,
                description: this.description,
                class: this.additional_class,
                icon: this.icon,
                position: this.position,
                device: this.device,
                roles: this.roles
            }
        }
    },
    validations: {
        roles: { 
            minRoles: function(){ 
                return (this.roles.length > 0) ? true : false; 
            }
        }
    },
    methods: {
        updateRoles: function(updated_roles){
            this.menuItem.roles= updated_roles;
        },
        stringifyRoles: function(){
            var saveRoles = [];
            for(var a in this.$data.menuItem.roles)
            {
                saveRoles.push(this.$data.menuItem.roles[a].id);
            }
            return saveRoles.join(",");
        },
        combineDevices: function(){
            var checkedNames = [];
            for(var x = 0; x < this.$data.menuItem.device.length; x++)
            {
                if(this.$data.menuItem.device[x].checked == true)
                {
                    checkedNames.push(this.$data.menuItem.device[x].name);
                }
            }
            return checkedNames.join();
        },
        submit: function(){
            var me = this;
            me.$store.dispatch('app/overlayMessage', {
                message: 'Submitting, please wait...', 
                spinner: true 
            });
            var item = JSON.parse(JSON.stringify(this.$data.menuItem));
            item.device = me.combineDevices();
            item.roles = me.stringifyRoles();

            axios.post('/menus', qs.stringify(item), {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function(response){
                me.$store.dispatch('app/hideOverlayMessage');
                me.$emit('close');
                me.onSubmit();
            });
        }
    },
};
</script>
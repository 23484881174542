<template>
<div class="btn-group btn-group-sm" role="group" aria-label="Paging Buttons">
    <button type="button" v-on:click="$emit('go-to-first-page')" v-bind:disabled="isFirstDisabled" class="btn btn-secondary">First</button>
    <button type="button" v-on:click="$emit('go-to-prev-page')" v-bind:disabled="isPreviousDisabled" class="btn btn-secondary">Previous</button>
    <button type="button" v-on:click="$emit('go-to-next-page')" v-bind:disabled="isNextDisabled" class="btn btn-secondary">Next</button>
    <button type="button" v-on:click="$emit('go-to-last-page')" v-bind:disabled="isLastDisabled" class="btn btn-secondary">Last</button>
</div>
</template>
<script>
export default {
    props: { 
        recordsTotal: {
            type: Number,
            default: 0
        },
        recordsFiltered: {
            type: Number,
            default: 0
        },
        start: {
            type: Number,
            default: 0
        },
        length: {
            type: Number,
            default: 0
        },
        currentRowCount: {
            type: Number,
            default: 0
        }
    },
    computed: {
        startAt: function(){ 
            return (this.start < 0 || !this.currentRowCount) ? 0 : parseInt(this.start)+1; 
        },
        endAt: function(){ 
            return (!this.currentRowCount) ? 0 : parseInt(this.startAt) + parseInt(this.currentRowCount) - 1; 
        },
        isFirstDisabled: function(){
            return (!this.currentRowCount || !this.start) ? true : false;
        },
        isPreviousDisabled: function(){
            return (!this.currentRowCount || !this.start) ? true : false;
        },
        isNextDisabled: function(){
            return (!this.currentRowCount || this.endAt >= this.recordsFiltered) ? true : false;
        },
        isLastDisabled: function(){
            return (!this.currentRowCount || this.endAt >= this.recordsFiltered) ? true : false;
        }
    }
}
</script>
<template>
    <div class="row">
        <div class="col-sm-6">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text">From</span>
                </div>
                <select class="form-control" v-model="start_time" v-on:change="updateValue">
                    <option v-for="time in timeOptions()" v-bind:key="time" v-bind:value="time">{{ time }}</option>
                </select>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text">To</span>
                </div>
                <select class="form-control" v-model="end_time" v-on:change="updateValue">
                    <option v-for="time in timeOptions()" v-bind:key="time" v-bind:value="time">{{ time }}</option>
                </select>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            value: { type: String, default: '' },
            start: { type: Object, default: function(){ return { default: '' }; }},
            end: { type: Object, default: function(){ return { default: '' }; }}
        },
        data: function(){
            var cur_val = (this.value) ? JSON.parse(this.value) : '';
            return {
                start_time: (cur_val && cur_val.start) ? cur_val.start : this.start.default,
                end_time: (cur_val && cur_val.end) ? cur_val.end : this.end.default,
            }
        },
        methods: {
            timeOptions: function(){
                var options = [];
                var hour = 0;
                var min = '00';
                while(hour <= 23)
                {
                    options.push(this.leftPad(hour, 2)+':'+min);
                    if(min == '30')
                    {
                        hour++;
                    }
                    if(min == '00')
                    {
                        min = '30';
                    }
                    else
                    {
                        min = '00';
                    }
                }
                return options;
            },
            leftPad: function(n, width, z){
                z = z || '0';
                n = n + '';
                return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
            },
            getTimeValue: function()
            {
                return JSON.stringify({
                    start: this.start_time,
                    end: this.end_time
                });
            },
            notifyApplied: function()
            {
                if(
                    this.start_time != this.start.default ||
                    this.end_time != this.end.default
                ){
                    this.$emit('filter-applied');
                }
                else
                {
                    this.$emit('filter-unapplied');
                }
            },
            updateValue: function()
            {
                this.notifyApplied();
                this.$emit('input', this.getTimeValue());
            }
        },
        created() {
            this.notifyApplied();
            this.$emit('input', this.getTimeValue());
        }
    }
</script>
<template>
    <regular-page v-bind:pageTitle="'Shipment Pickups'">
        <template v-slot:title-bar>
            <div class="btn-group btn-group-sm" role="group" aria-label="Page Submenu">
                <button v-on:click="exportToExcel" type="button" class="btn btn-secondary">
                    Export&nbsp;&nbsp;<i class="fas fa-file-export"></i>
                </button>
            </div>
        </template>
        <div class="row">
            <div class="col-xs-12 w-100">
                <d-table 
                    ref="pickups_table"
                    v-bind:tableId="'table_pickups'"
                    v-bind:saveState="true"
                    v-bind:serverSide="true"
                    v-bind:paging="true"
                    v-bind:pageLength="25"
                    v-bind:scrollY="'400px'"
                    v-bind:ajax="'/pickups/list'"
                    v-bind:columns="columns"
                    v-bind:filters="filters"
                    v-bind:shortcutFilters="shortcutFilters"
                    v-bind:rowIdKey="'hawb_id'"
                    v-bind:order="[{ column: 2, dir: 'asc' }]"
                    v-on="$listeners"
                    v-on:pickup-confirm="confirmPickup"
                    v-on:pickup-attach-doc="attachDocument"
                    v-on:pickup-view-doc="viewDocuments"
                    v-on:pickup-request-auth="requestAuth"
                    v-on:pickup-report-osd="reportOsd"
                    v-on:hawb-details-click="summary"
                    v-bind:tableClass="{'table-sm':true}"
                ></d-table>
            </div>
        </div>
    </regular-page>
</template>

<script>
    import DTable from './dtable/Table';
    import PickupConfirmDialog from './PickupConfirmDialog';
    import AttachDocumentDialog from './AttachDocumentDialog';
    import ViewDocumentsDialog from './ViewDocumentsDialog';
    import RequestAuthDialog from './RequestAuthDialog';
    import ReportOsdDialog from './ReportOsdDialog';
	import SummaryDialog from './SummaryDialog';
    export default {
        params: ["users"],
        components: {
            'd-table': DTable
        },
        data: function(){
            return {
                columns: [{ 
                    title: 'Hawb', 
                    data: 'hawb_number', 
                    name: 'hawb_number',
		    renderer: 'hawb-button',
                    orderable: true
                },{ 
                    title: 'Status', 
                    data: 'status', 
                    name: 'status',
                    renderer: 'status-cell',
                    orderable: true
                },{ 
                    title: 'Date', 
                    data: 'hawb_date', 
                    name: 'hawb_date',
                    orderable: true
                },{ 
                    title: 'Ready', 
                    data: 'ready_time', 
                    name: 'ready_time',
                    shrink:true,
                    orderable: true,
                    renderer: 'plain',
                    rendererProps: {
                        locale: 'en-US'
                    }
                },{ 
                    title: 'Close', 
                    data: 'close_time',
                    name: 'close_time',
                    shrink:true,
                    width: 30,
                    orderable: true,
                    renderer: 'plain',
                    rendererProps: {
                        locale: 'en-US'
                    }
                },{ 
                    title: 'Driver', 
                    data: 'driver_name',
                    name: 'driver_name',
                    orderable: true
                },{ 
                    title: 'Location', 
                    data: 'shipper_name', 
                    name: 'shipper_name',
                    orderable: true,
                    renderer: 'location',
                    rendererProps: {
                        name: '{{data}}',
                        address: '{{row.shipper_address}}'
                    }
                },{ 
                    title: 'City', 
                    data: 'shipper_city', 
                    name: 'shipper_city',
                    orderable: true,
                },{ 
                    title: 'Zip', 
                    data: 'shipper_zip', 
                    name: 'shipper_zip',
                    orderable: true
                },{ 
                    title: 'Pallets', 
                    data: 'pallets', 
                    name: 'pallets',
                    orderable: true,
                    renderer: 'number',
                    rendererProps: {
                        locale: 'en-US'
                    }
                },{ 
                    title: 'Pieces', 
                    data: 'pieces', 
                    name: 'pieces',
                    orderable: true,
                    renderer: 'number',
                    rendererProps: {
                        locale: 'en-US'
                    }
                },{ 
                    title: 'Weight', 
                    data: 'weight', 
                    name: 'weight',
                    orderable: true,
                    renderer: 'number',
                    rendererProps: {
                        locale: 'en-US'
                    }
                },{ 
                    title: 'Actions', 
                    data: 'hawb_id', 
                    name: 'actions',
                    renderer: 'pickup-actions',
                    rendererProps: {
                        hawbData: "{{row}}"
                    }
                }],
                shortcutFilters: [{
                    label: "Hawb&nbsp;Date",
                    type: "DateRangeToggleFilter",
                    name: "hawb_date",
                    props: {
                        ranges: [{
                            active: true,
                            tooltip: "Show All",
                            label: "All"
                        },{
                            active: false,
                            startAtDay: 0,
                            endAtDay: 0,
                            tooltip: "Today",
                            label: '<i class="fas fa-calendar"></i>'
                        },{
                            active: false,
                            startAtDay: 1,
                            endAtDay: 1,
                            tooltip: "Tomorrow",
                            label: '<i class="fas fa-calendar-day"></i>'
                        },{
                            active: false,
                            startAtDay: 0,
                            endAtDay: 6,
                            tooltip: "Next 7 Days",
                            label: '<i class="fas fa-calendar-week"></i>'
                        }]
                    }
                }],
                filters: [{
                    label: "Hawb Date",
                    type: "DateRangeFilter",
                    name: "hawb_date",
                    props: {
                        start: {
                            default: ""
                        },
                        end: {
                            default: ""
                        }
                    }
                },{
                    label: "Ready Time",
                    type: "TimeRangeFilter",
                    name: "ready_time",
                    props: {
                        start: {
                            default: ""
                        },
                        end: {
                            default: ""
                        }
                    }
                },{
                    label: "Close Time",
                    type: "TimeRangeFilter",
                    name: "close_time",
                    props: {
                        start: {
                            default: ""
                        },
                        end: {
                            default: ""
                        }
                    }
                },{
                    label: "Driver",
                    type: "SelectFilter",
                    name: "driver",
                    props: {
                        default: "all",
                        options: [{
                            text: "All",
                            value: "all"
                        }],
                        ajax: {
                            url: "pickups/drivers",
                        }
                    }
                },{
                    label: "Pick Up Confirmed",
                    type: "SelectFilter",
                    name: "pickup_confirmed",
                    props: {
                        default: "no",
                        options: [{
                            text: "All",
                            value: "all"
                        },{
                            text: "Yes",
                            value: "yes"
                        },{
                            text: "No",
                            value: "no"
                        }]
                    }
                },{
                    label: "Location Name",
                    type: "LocationFilter",
                    name: "location_filter",
                    props: {
                        default: "",
                    }
                }]
            }
        },
        methods: {
            exportToExcel: function(){
                var me = this;
                
                me.$store.dispatch('app/overlayMessage', {
                    message: 'Exporting data to Excel, please wait...',
                    spinner: true
                });
                
                var lastRequestData = me.$refs.pickups_table.getLastRequest();
                lastRequestData.start = 0;
                lastRequestData.length = 0;
                axios.post("/pickups/export_excel", qs.stringify(lastRequestData), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    responseType: "blob"
                }).then(function(response){
                    me.$store.dispatch('app/hideOverlayMessage');
                    const type = response.headers['content-type']
                    var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: type, encoding: 'UTF-8' }));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'pickups_'+moment().format('YYYY_MM_DD_h_mm_ss')+'.xlsx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }).catch(function(){
                    me.$store.dispatch('app/hideOverlayMessage');
                });
            },
            attachDocument: function(hawb_data){
                var me = this;
                this.$modal.show(AttachDocumentDialog, {
                    dialogTitle: "Attach Document",
                    dialogSubTitle: "Hawb Number: "+hawb_data.hawb_number,
                    entityId: parseInt(hawb_data.hawb_id),
                    entityType: "hawb",
                    allowedExtentions: ['pdf','jpg','jpeg'],
                    docTypeSelected: "bol",
                    docTypeOptions: [{value: "bol", label: "BOL"}],
                    onSubmit: function(values){
                        me.$refs.pickups_table.reRequestData();
                    }
                },{ 
                    height: 'auto',
                    scrollable: true,
                    adaptive: true,
                    clickToClose: false
                });
            },
            viewDocuments: function(hawb_data){
                var me = this;
                this.$modal.show(ViewDocumentsDialog, {
                    dialogTitle: "View Documents",
                    dialogSubTitle: "Hawb Number: "+hawb_data.hawb_number,
                    entityId: parseInt(hawb_data.hawb_id),
                    entityType: "hawb",
                    docTypes: ["bol"],
                    onAttachNewProps: hawb_data,
                    onAttachNew: function(props){
                        me.attachDocument({
                            hawb_id: props.hawb_id, 
                            hawb_number: props.hawb_number
                        });
                    }
                },{ 
                    height: 'auto',
                    scrollable: true,
                    adaptive: true,
                    clickToClose: false
                });
            },
            confirmPickup: function(hawb_data){
                var me = this;
                this.$modal.show(PickupConfirmDialog, {
                    
                    hawbId: parseInt(hawb_data.hawb_id),
                    hawbNumber: hawb_data.hawb_number,
                    pickupConfirmDate: moment().format(),
                    pieces: hawb_data.pieces ? parseInt(hawb_data.pieces) : 0,
                    weight: hawb_data.weight ? parseInt(hawb_data.weight) : 0,
                    pallets: hawb_data.pallets ? parseInt(hawb_data.pallets) : 0,
                    driverId: hawb_data.driver_id ? hawb_data.driver_id.toString() : '',
                    onSubmit: function(values){
                        me.$refs.pickups_table.reRequestData();
                    }
                },{ 
                    height: 'auto',
                    scrollable: true,
                    adaptive: true
                });
            },
            requestAuth: function(hawb_data){
                var me = this;
                this.$modal.show(RequestAuthDialog, {
                    hawbId: parseInt(hawb_data.hawb_id),
                    hawbNumber: hawb_data.hawb_number,
                    agentType: "pickup",
                    onSubmit: function(values){
                        me.$refs.pickups_table.reRequestData();
                    }
                },{ 
                    height: 'auto',
                    scrollable: true,
                    adaptive: true
                });
            },
            reportOsd: function(hawb_data){
                var me = this;
                this.$modal.show(ReportOsdDialog, {
                    
                    hawbId: parseInt(hawb_data.hawb_id),
                    hawbNumber: hawb_data.hawb_number,
                    agentType: "pickup",
                    onSubmit: function(values){
                        me.$refs.pickups_table.reRequestData();
                    }
                },{ 
                    height: 'auto',
                    scrollable: true,
                    adaptive: true
                });
            },
			summary: function(hawb_data){
				var me = this;
                
                me.$store.dispatch('app/overlayMessage', {
                    message: 'Fetching shipment details, please wait...',
                    spinner: true
                });
                
                axios.get("/pickups/hawb_details/"+hawb_data.hawb_id).then(function(response){
                    me.$store.dispatch('app/hideOverlayMessage');
                    var data = response.data.hawb;
                    
                    me.$modal.show(SummaryDialog, {
                    
                        hawbId: parseInt(data.hawb_id),
                        hawbNumber: data.hawb_number,
                        agentType: "pickup", 
                        hawb_date: moment(data.hawb_date).format('MM/D/YYYY'), 
                        pieces: data.pieces ? parseInt(data.pieces) : 0,
                        weight: data.weight ? parseInt(data.weight) : 0,
                        pallets: data.pallets ? parseInt(data.pallets) : 0,
                        pickup_location: data.pickup_location,
                        shipper_name: data.shipper_name,
                        shipper_address: data.shipper_address,
                        shipper_city: data.shipper_city,
                        shipper_zip: data.shipper_zip,
                        shipper_state: data.shipper_state,
                        pos: data.pos,
                        apptnotes: data.apptnotes,
                        pickup_instruction: data.pickup_instruction,
                        showLess: (data.pos.length >15)? true : false
                    },{ 
                        height: 'auto',
                        scrollable: true,
                        adaptive: true
                    });
                    
                }).catch(function(){
                    me.$store.dispatch('app/hideOverlayMessage');
                });
			}
        }
    }
</script>

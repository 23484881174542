<template>
<span>{{ newDate }}</span>
</template>

<script>
    export default {
        props: {
            login: { type: String, default: '' }

        },
        data: function () {
            return {
                newDate: (this.login != null)?moment(moment.utc(this.login).toDate()).local().format('MM/DD/YYYY HH:mm:ss'):'Not Available'
            }
        },
        mounted:function(){
            //var tz = moment(this.login).subtract(moment(this.login).utcOffset(), 'minutes').utc();
            var stillUtc = moment.utc(this.login).toDate();
            var local = moment(moment.utc(this.login).toDate()).local().format('YYYY-MM-DD HH:mm:ss');
            console.log(local);
        }
    }
</script>
<template>
    <ul>
        <li v-if="user" class="nav-item dropdown">
            <a id="navbarDropdown" class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-user"></i> {{ user.name }} <span class="caret"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <a href="#" class="dropdown-item" v-on:click="showProfile"><i class="fas fa-id-badge" style="min-width: 20px;"></i> Profile</a>
                <a class="dropdown-item" href="#" v-on:click="logoutUser"><i class="fas fa-sign-out-alt" style="min-width: 20px;"></i> Sign Out</a>
            </div>
            <form id="logout-form" action="/logout" method="POST" style="display: none;">
                <input type="hidden" name="_token" v-bind:value="csrfToken" />
            </form>
        </li>
    </ul>
</template>
<script>
import UserProfileDialog from '../UserProfileDialog';
export default {
    data: function(){
        return { 
            csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    },
    computed: {
        user: function(){
            //Get user data from user store
            var user_data = JSON.parse(JSON.stringify(this.$store.state.user.data));
            if(!user_data)
            {
                return false;
            }
            
            //Decide how to display users name
            user_data.name = user_data.username;
            if(user_data.first_name && user_data.last_name)
            {
                user_data.name = user_data.first_name+' '+user_data.last_name;
            }
            
            return user_data;
        }
    },
    methods: {
        showProfile: function()
        {
            var me = this;
            this.$modal.show(UserProfileDialog, {},{ 
                height: 'auto',
                scrollable: true,
                adaptive: true
            });
        },
        logoutUser: function(e)
        {
            e.preventDefault();
            this.$store.dispatch('app/overlayMessage', {
                message: 'Logging out, please wait...',
                spinner: true
            });
            document.getElementById('logout-form').submit();
        }
    }
}
</script>
<template>
    <regular-page v-bind:pageTitle="label">
        <div class="row">
            <div class="col-xs-12 w-100 pb-2">
                <div class="list-group" v-if="subs.length > 0">
                    <router-link 
                        v-for="(item) in subs" 
                        v-bind:to="prependToUrl+'/'+item.identifier" 
                        v-bind:key="item.label"
                        class="list-group-item list-group-item-action">
                        <i v-if="item.icon" class="fa" v-bind:class="item.icon"></i> {{ item.label }}
                    </router-link>
                </div>
            </div>
        </div>
    </regular-page>
</template>
<script>
export default {
    props: {
        label: String,
        prependToUrl: String,
        subs: Array
    }
}
</script>
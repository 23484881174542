<template>
    <input class="form-control form-control-sm" v-model="location_filter_value" v-on:change="updateValue" >
</template>
<script>
    export default {
        props: {
            default: { type: String, default: '' },
            value: { type: String, default: '' },
        },
        data: function(){
            return {
                location_filter_value: this.value ? this.value : this.default,
            }
        },
        methods: {
            clear: function()
            {
            },
            notifyApplied: function()
            {
                if(this.default != this.location_filter_value)
                {
                    this.$emit('filter-applied');
                }
                else
                {
                    this.$emit('filter-unapplied');
                }
            },
            updateValue: function()
            {
                this.notifyApplied();
                this.$emit('input', this.location_filter_value);
            }
        },
        created(){
            this.notifyApplied();
            this.$emit('input', this.location_filter_value);
        }
    }
</script>
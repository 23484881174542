/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import VueRouter from 'vue-router';
import VueInternationalization from 'vue-i18n';
import Locale from './vue-i18n-locales.generated';
import QueryString from 'qs';
import Axios from "axios";
import VModal from 'vue-js-modal';
import Vuex from 'vuex';
import FileUpload from 'v-file-upload'
import Vuelidate from 'vuelidate'
import Dialog from './components/DialogModal';

//Make sure all ajax requests made via axios contain the csrf token
window.qs = require('qs');
window.axios = require('axios');
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

//Load main Vue
window.Vue = require('vue');

/**
 * Load language translations from laravel
 * Use: "php artisan vue-i18n:generate" command to regenerate lang strings.
 * Src: https://github.com/martinlindhe/laravel-vue-i18n-generator
 */
Vue.use(VueInternationalization);
const i18n = new VueInternationalization({
    locale: 'en',
    messages: Locale
});

/**
 * Modal and dialog library
 */
Vue.use(VModal, { dialog: true, dynamic: true });


/**
 * Vuex for centralized state management
 */
Vue.use(Vuex);
import store from './store'


/**
 * Fileupload component to allow file uploading
 */
Vue.use(FileUpload);

/**
 * Form validation library
 */
Vue.use(Vuelidate)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

//Vue.component('main-header', require('./components/MainHeader.vue').default);
//Vue.component('admin-user-management', require('./components/admin/UserManagement.vue').default);


/**
 * Vue router, used for single page web app navigation
 */
Vue.use(VueRouter);

var subUser = { template: '<sub-menu></sub-menu>'};

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: "active",
    routes: []
});

router.beforeEach((to, from, next) => {
    if(
        to.matched.length > 0 && 
        to.matched[0].props && 
        to.matched[0].props.default && 
        to.matched[0].props.default.roles &&
        to.matched[0].props.default.roles.length
    ){
        var roleNames = [];
        for(var r in to.matched[0].props.default.roles)
        {
            roleNames.push(to.matched[0].props.default.roles[r].name);
        }
        if(store.getters["user/hasRoleAnyOf"](roleNames))
        {
            next();
        }
        else
        {
            next('/access_denied');
        }
    }
    else
    {
        next();
    }
});

const app = new Vue({ 
    el: '#app', 
    i18n, 
    store, 
    router 
});
app.$router.addRoutes([{ path: '/', name: 'home', component: { template: '<home></home>' }}]);
app.$router.addRoutes(app.$store.getters["menu/generateRoutes"]("main_menu"));
app.$router.addRoutes(app.$store.getters["menu/generateRoutes"]("admin"));
app.$router.addRoutes([{ path: '/access_denied', name: 'access_denied', component: { template: '<access-denied></access-denied>' }}]);
app.$router.addRoutes([{ path: '*', name: 'page_not_found', component: { template: '<page-not-found></page-not-found>' }}]);


//Intercept all axios responses to check for CSRF token mismatch which would indicate an expired session
axios.interceptors.response.use(function (response){
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response; //Return the response and let it get handled as intended.
}, function(error){
    if(error.response.status == 419 && error.response.data && error.response.data.message == "CSRF token mismatch.")
    {
        app.$modal.show(Dialog, {
            title: "Session Expired",
            message: '<div class="text-center">Your session may have expired. Please click Login to log back in.</div>',
            type: 'danger',
            closeable: false,
            buttons: [{
                label: "Login",
                align: "right",
                type: "primary",
                action: function(dialog){
                    dialog.$emit("close");
                    store.commit('app/overlayMessage', {
                        message: 'Loading login page, please wait...',
                        spinner: true
                    });
                    window.location.href="/login";
                }
            }]
        },{
            height: 'auto',
            adaptive: true,
            clickToClose: false
        });
    }
    return Promise.reject(error);
});

<template>
    <div v-if="this.data == 'Delivered' || this.data == 'Pickup Confirmed'">
        <span class="text-success"><strong>{{ this.data }}</strong></span>
    </div>
    <div v-else-if="this.data == 'Processing'">
        <span class="text-info"><em>{{ this.data }}</em></span>
    </div>
    <div v-else>{{ this.data }}</div>
</template>

<script>
    export default {
        props: {
            data: {
                type: [String, Number, Boolean, Array, Object],
                default: ''
            },
            row: Object
        }
    }
</script>
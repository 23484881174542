
// initial state
const state = {
    items: JSON.parse(JSON.stringify(window.menu))
}

// getters
const getters = {
    generateRoutes: state => (root_identifier) => {
        
        var getMultiDimRoutes = function(menu, identTree){
            identTree = identTree ? identTree : '';
            var ret = [];
            for(var x = 0; x < menu.length; x++)
            {
                if((menu[x].subs.length > 0))
                {
                    menu[x].prependToUrl = identTree+'/'+menu[x].identifier;
                    ret.push({
                        path: identTree+'/'+menu[x].identifier,
                        name: menu[x].label,
                        component: { template: '<sub-menu  v-bind=\'' + JSON.stringify(menu[x]) + '\'></sub-menu>'},
                        props: { roles: menu[x].roles }
                    });
        
                    var result = getMultiDimRoutes(menu[x].subs, identTree+'/'+menu[x].identifier);
                    for(var y = 0; y < result.length; y++){
                        ret.push(result[y]);
                    }
                }
                else
                {
                    ret.push({
                        path: identTree+'/'+menu[x].identifier,
                        name: menu[x].label,
                        component: { template: menu[x].component },
                        props: { roles: menu[x].roles }
                    });
                }
            }
            return ret;
        };
        
        var dynamicRoutes = [];
        for(var i in state.items)
        {
            if(state.items[i].identifier == root_identifier)
            {
                dynamicRoutes = getMultiDimRoutes(JSON.parse(JSON.stringify(state.items[i].subs)));
            }
        }
        
        return dynamicRoutes;
    }
}

// actions
const actions = {
    refreshMenuData(context){
        axios.get('/menus/list', [], {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(response){
            context.commit('setMenuData', JSON.parse(JSON.stringify(response.data)));
        });
    }
}

// mutations
const mutations = {
    setMenuData(state, newData){
        Vue.set(state, 'items', newData);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
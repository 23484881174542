
// initial state
const state = {
    data: JSON.parse(JSON.stringify(window.user)),
    elevated_user: window.elevated_user ? JSON.parse(JSON.stringify(window.elevated_user)) : false
}

// getters
const getters = {
    hasRole: (state) => (roleName) => {
        for(var r in state.data.roles)
        {
            if(state.data.roles[r].name == roleName)
            {
                return true;
            }
        }
    },
    hasRoleAnyOf: (state, getters) => (roleNames) => {
        for(var i in roleNames)
        {
            if(getters.hasRole(roleNames[i]))
            {
                return true;
            }
        }
        return false;
    }
}

// actions
const actions = {
    loginAs(context, userId){
        return new Promise((resolve, reject) => {
            if(context.state.elevated_user)
            {
                axios.get('/user/switch/'+userId, {}, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    if(response.data && response.data.success)
                    {
                        context.commit('updateUserData', response.data.data);
                        resolve();
                    }
                    else if(response.data.message)
                    {
                        reject(response.data.message);
                    }
                    else
                    {
                        reject("There was an error switching to the requested user.");
                    }
                },function(){
                    reject("There was an error switching to the requested user.");
                });
            }
            else
            {
                reject("You cannot switch to the requested user.");
            }
        });
    },
    submitNewData(context, newData){
        return new Promise((resolve, reject) => {
            var allowedToUpdate = {};
            for(var p in newData)
            {
                if(['first_name','last_name','email','phone'].includes(p))
                {
                    allowedToUpdate[p] = newData[p];
                }
            }
            allowedToUpdate["id"] = context.state.data.id;
            axios.post('/user/update', qs.stringify(allowedToUpdate), {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function(response){
                if(response.data && response.data.success)
                {
                    context.commit('updateUserData', allowedToUpdate);
                    resolve();
                }
                else if(response.data.message)
                {
                    reject(response.data.message);
                }
                else
                {
                    reject("There was an error while updating user data.");
                }
            },function(){
                reject("There was an error updating user user data.");
            });
        });
    },
}

// mutations
const mutations = {
    updateUserData(state, newData){
        var keys = [];
        for(var x  in state.data){
            keys.push(x);
        }
        
        for(var p in newData)
        {
            if(keys.indexOf(p) >= 0)
            {
                state.data[p] = newData[p];
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
    <datepicker 
        v-bind:input-class="'form-control-sm color-bg-white'" 
        v-bind:calendar-class="'datepicker-calendar'" 
        v-bind:bootstrap-styling="true"
        v-bind:format="formatted"
        v-model="date"
        @input="updateValue"
    ></datepicker>
</template>
<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        components: {
            Datepicker
        },
        props: {
            default: { type: String, default: '' },
            value: { type: String, default: '' }
        },
        data: function(){
            return {
                date: (this.value) ? moment(this.value).format() : (this.default ? moment(this.default).format() : '')
            }
        },
        computed: {
            formatted: function(){
                return this.date ? moment(this.date).format('L') : '';
            }
        },
        methods: {
            clear: function()
            {
                if(this.default)
                {
                    this.date = moment(this.default, 'L').toDate()
                }
                else
                {
                    this.date = '';
                }
                this.updateValue();
            },
            notifyApplied: function()
            {
                if(this.formatted != this.default)
                {
                    this.$emit('filter-applied');
                }
                else
                {
                    this.$emit('filter-unapplied');
                }
            },
            updateValue: function()
            {
                this.notifyApplied();
                this.$emit('input', this.formatted);
            }
        },
        created() {
            this.notifyApplied();
            this.$emit('input', this.formatted)
        }
    }
</script>
<template>
<dialog-modal v-on="$listeners">
    <strong slot="header">Privacy Policy</strong>
    <div v-html="privacypolicy" slot="body"></div>
    <button slot="footer" v-on:click="$emit('close')" type="button" class="btn btn-primary btn-sm float-right">Close</button>
</dialog-modal>
</template>
<script>
export default {
    data: function(){
        return { privacy_policy_content: "" };
    },
    computed: {
        privacypolicy: function(){
            return this.$data.privacy_policy_content ? this.$data.privacy_policy_content : "Loading, please wait...";
        }
    },
    mounted: function(){
        var me = this;
        axios({ method: 'get', url: '/terms/raw_privacy_policy', responseType: 'text'}).then(function(response){
            me.$data.privacy_policy_content = response.data;
        });
    },
}
</script>
<template>
<div class="card bg-light">
    <div class="card-header">
        <div class="d-flex justify-content-around justify-content-lg-between justify-content-xl-between flex-wrap">
            <div class="p-2 order-1 order-sm-1 order-md-1 order-lg-1">
                <d-search v-if="searching"
                    v-bind:searchTerm="requestQueue.search.value"
                    v-on:search="doSearch"
                ></d-search>
            </div>
            <div class="p-2 order-3 order-sm-3 order-md-3 order-lg-2">
                <d-shortcut-filters
                    v-if="isShortcutFilters"
                    :key="filterUpdateCount"
                    v-bind:filters="shortcutFilters"
                    v-on:filter-update="onFilterUpdate"
                    v-on:filter-clear="filterClear"
                    class="mr-2"
                ></d-shortcut-filters>
            </div>
            <div v-if="(paging || isFilters)" class="p-2 order-2 order-sm-2 order-md-2 order-lg-3">
                <d-rows-per-page v-if="paging"
                    v-on:rows-per-page-change="updateRowsPerPage"
                    v-bind:rowsPerPage="pageLength"
                    class="float-right"
                ></d-rows-per-page>
                <d-filters-button 
                    v-if="isFilters"
                    v-bind:totalFiltersApplied="totalFiltersApplied"
                    v-on:show-filters="showFiltersDialog"
                    class="float-right mr-2"
                ></d-filters-button>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="overflow:auto;">
        <table v-bind:class="tableClasses">
            <thead>
                <tr>
                    <d-column
                        v-for="(column, colIndex) in columns" 
                        v-bind:key="column.name" 
                        v-bind:index="colIndex"
                        v-bind:order="lastRequest.order"
                        v-bind:ordering="ordering"
                        v-bind="column" 
                        v-on:sort="doSort"
                    ></d-column>
                </tr>
            </thead>
            <tbody>
                <tr v-if="rows.length == 0"><td :colspan="columns.length" v-html="emptyTableText"></td></tr>
                <d-row v-for="row in rows" v-bind:key="row[rowIdKey]" v-bind:data="row">
                    <d-cell
                        v-for="cell in columns"
                        v-on="$listeners"
                        v-bind:key="'cell-'+cell.name"
                        v-bind:data="row[cell.data]"
                        v-bind:rowData="row"
                        v-bind:renderer="cell.renderer"
                        v-bind:rendererProps="cell.rendererProps"
                        v-bind:columnTitle="cell.title"
                    >
                    </d-cell>
                </d-row>
            </tbody>
        </table>
    </div>
    <div v-if="(info || paging)" class="card-footer">
        <div class="d-flex justify-content-around justify-content-lg-between justify-content-xl-between flex-wrap">
            <div v-if="info" class="p-2">
                <d-info class="float-left" 
                    v-bind:recordsTotal="lastResponse.recordsTotal" 
                    v-bind:recordsFiltered="lastResponse.recordsFiltered" 
                    v-bind:start="lastResponse.start" 
                    v-bind:length="lastResponse.length" 
                    v-bind:currentRowCount="currentRowCount" 
                ></d-info>
            </div>
            <div v-if="paging" class="p-2">
                <d-paging-buttons class="float-right" 
                    v-bind:recordsTotal="lastResponse.recordsTotal" 
                    v-bind:recordsFiltered="lastResponse.recordsFiltered" 
                    v-bind:start="lastResponse.start" 
                    v-bind:length="lastResponse.length" 
                    v-bind:currentRowCount="currentRowCount" 
                    v-on:go-to-first-page="goToFirstPage" 
                    v-on:go-to-prev-page="goToPrevPage" 
                    v-on:go-to-next-page="goToNextPage" 
                    v-on:go-to-last-page="goToLastPage" 
                ></d-paging-buttons>
            </div>
        </div>
    </div>
    <d-processing v-if="isProcessing"></d-processing>
</div>
</template>
<script>
import qs from 'qs';
import DColumn from './Column';
import DRow from './Row';
import DCell from './Cell';
import DSearch from './Search';
import DFiltersButton from './FiltersButton';
import DShortcutFilters from './ShortcutFilters';
import DFiltersDialog from './FiltersDialog';
import DRowsPerPage from './RowsPerPage';
import DInfo from './Info';
import DPagingButtons from './PagingButtons';
import DProcessing from './Processing';

export default {
    props: { 
        tableId: { type: String, default: '' },
        serverSide: { type: Boolean, default: false },
        tableClass: { type: Object, default: function(){ return {}; }},
        paging: { type: Boolean, default: false },
        pageLength: { type: Number, default: 10 },
        info: { type: Boolean, default: true },
        order: { type: Array, default: function(){ return [{ column: 0, dir: 'asc' }]; }},
        ordering: { type: Boolean, default: true },
        fetchData: { type: Function },
        processing: Boolean,
        searching: { type: Boolean, default: true },
        scrollY: String,
        scrollCollapse: Boolean,
        ajax: String,
        emptyTable: { 
            type: String, 
            default: '<center><small>There is currently no data to display</small></center>' 
        },
        columns: Array,
        filters: { 
            type: Array,
            default: function(){ return []; }
        },
        shortcutFilters: { 
            type: Array,
            default: function(){ return []; }
        },
        rowIdKey: { type: String, default: 'id' },
        rowData: Array,
        saveState: { type: Boolean, default: false }
    },
    data: function(){
        return {
            filterUpdateCount: 0,
            state: false,
            totalFiltersApplied: 0,
            rows: [],
            clientSideData: [],
            requestQueue: {
                draw: 0,
                columns: this.columns,
                order: this.order,
                start: 0,
                length: this.pageLength,
                search: { value: '', regex: false },
                filters: {}
            },
            lastRequest: {
            },
            lastResponse: {
                start: 0,
                length: 0,
                recordsFiltered: 0,
                recordsTotal: 0
            }
        };
    },
    computed: {
        emptyTableText: function(){ return (this.state=="processing") ? "<center><small>Please wait while data loads...</small></center>" : this.$props.emptyTable; },
        currentRowCount: function(){ return this.rows.length; },
        isProcessing: function(){ return (!this.state || this.state=="processing") ? true : false; },
        isFilters: function(){ return (this.filters.length > 0) ? true : false; },
        isShortcutFilters: function(){ return (this.shortcutFilters.length > 0) ? true : false; },
        tableClasses: function(){
            var all_classes = { "table": true, "w-100": true, "mb-0": true };
            for(var c in this.$props.tableClass)
            {
                all_classes[c] = true;
            }
            return all_classes;
        }
    },
    components: {
        'd-row': DRow,
        'd-column': DColumn,
        'd-cell': DCell,
        'd-search': DSearch,
        'd-filters-button': DFiltersButton,
        'd-shortcut-filters': DShortcutFilters,
        'd-rows-per-page': DRowsPerPage,
        'd-info': DInfo,
        'd-paging-buttons': DPagingButtons,
        'd-processing': DProcessing,
    },
    methods: {
        getLastRequest: function(){
            let me = this;
            return JSON.parse(JSON.stringify(me.lastRequest));
        },
        requestData: function(){
            let me = this;
            if(this.serverSide)
            {
                return me.ajaxRequest();
            }
            else if(me.fetchData && me.clientSideData.length == 0)
            {
                me.state = "processing";
                me.rows = [];
                me.fetchData(function(data){
                    me.clientSideData = JSON.parse(JSON.stringify(data));
                    return me.parseClientSideData();
                });
            }
            else
            {
                me.rows = [];
                return me.parseClientSideData();
            }
        },
        updateData: function(){
                let me = this;
                me.state = "processing";
                me.rows = [];
                me.fetchData(function(data){
                    me.clientSideData = JSON.parse(JSON.stringify(data));
                    return me.parseClientSideData();
                });
        },
        parseClientSideData: function(){
            let me = this;
            me.state = "processing";
            me.requestQueue.draw = parseInt(me.requestQueue.draw)+1;
            me.lastRequest = JSON.parse(JSON.stringify(me.requestQueue));
            
            var d = JSON.parse(JSON.stringify(me.clientSideData));
            
            if(me.lastRequest.search && me.lastRequest.search.value)
            {
                var matched = [];
                for(var si in d)
                {
                    if(Object.values(d[si]).join(" ").toLowerCase().includes(me.lastRequest.search.value.toLowerCase()))
                    {
                        matched.push(d[si]);
                    }
                }
                d = matched;
            }
            
            var total = d.length;
            
            if(me.lastRequest.order.length > 0)
            {
                var sortCol = me.lastRequest.columns[me.lastRequest.order[0].column];
                var sortDir = me.lastRequest.order[0].dir;
                d.sort(function(a, b){
                    var aSortI = false;
                    if(sortCol && sortCol.hasOwnProperty("data") && sortCol.data && a.hasOwnProperty(sortCol.data))
                    {
                        aSortI = sortCol.data;
                    }
                    else if(sortCol && sortCol.hasOwnProperty("name") && sortCol.name && a.hasOwnProperty(sortCol.name))
                    {
                        aSortI = sortCol.name;
                    }
                    
                    var bSortI = false;
                    if(sortCol && sortCol.hasOwnProperty("data") && sortCol.data && b.hasOwnProperty(sortCol.data))
                    {
                        bSortI = sortCol.data;
                    }
                    else if(sortCol && sortCol.hasOwnProperty("name") && sortCol.name && b.hasOwnProperty(sortCol.name))
                    {
                        bSortI = sortCol.name;
                    }
                    
                    if(aSortI && bSortI)
                    {
                        if(isNaN(a[aSortI]) || isNaN(b[bSortI]))
                        {
                            return ('' + a[aSortI]).localeCompare(b[bSortI]);
                        }
                        else
                        {
                            return parseInt(a[aSortI]) - parseInt(b[bSortI]);
                        }
                    }
                    
                    return 0;
                });
                if(sortDir == "desc")
                {
                    d.reverse();
                }
            }
            
            if(me.lastRequest["start"] > 1)
            {
                d.splice(0, (me.lastRequest["start"]-1));
            }
            
            if(me.lastRequest["length"] && d.length > me.lastRequest["length"])
            {
                var removeLength = d.length - me.lastRequest["length"];
                d.splice(d.length - removeLength, removeLength);
            }
            
            me.handleDataResponse({
                draw: me.lastRequest.draw,
                data: d,
                recordsTotal: total,
                recordsFiltered: total
            });
        },
        ajaxRequest: function(){
            let me = this;
            me.state = "processing";
            this.rows = [];
            this.requestQueue.draw = parseInt(this.requestQueue.draw)+1;
            this.lastRequest = JSON.parse(JSON.stringify(this.requestQueue));
            axios.post(this.ajax, qs.stringify(this.requestQueue), {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function(response){
                me.handleDataResponse(response.data);
            });
        },
        handleDataResponse: function(response_data){
            if(this.lastRequest.draw <= response_data.draw)
            {
                this.rows = response_data.data;
                this.lastResponse.recordsTotal = response_data.recordsTotal;
                this.lastResponse.recordsFiltered = response_data.recordsFiltered;
                this.lastResponse.start = parseInt(this.lastRequest.start);
                this.lastResponse.length = parseInt(this.lastRequest.length);
                this.state = "ready";
                setTimeout(function(){
                    $('table [data-toggle="tooltip"]').tooltip();
                }, 200);
            }
        },
        goToFirstPage: function(){
            this.requestQueue.start = 0
            this.requestData();
        },
        goToPrevPage: function(){
            var start = (parseInt(this.lastResponse.start) - parseInt(this.lastResponse["length"]));
            this.requestQueue.start = (start <= 0) ? 0 : start;
            this.requestData();
        },
        goToNextPage: function(){
            var pageStart = parseInt(this.lastResponse.start);
            var start = ((pageStart < 1 ? 1 : pageStart) + parseInt(this.lastResponse["length"]));
            this.requestQueue.start = (start <= 0) ? 0 : start;
            this.requestData();
        },
        goToLastPage: function(){
            var start = (Math.floor(this.lastResponse.recordsFiltered/this.lastResponse["length"])*this.lastResponse["length"])+1;
            this.requestQueue.start = (start <= 0) ? 0 : start;
            this.requestData();
        },
        doSearch: function(term){
            this.requestQueue.search.value = term;
            this.requestQueue.start = 0;
            this.requestData();
        },
        doToday: function(){
            var todaysDate = moment().format("YYYY-MM-DD");
            this.requestQueue.filters.appt_date_range = '{"start": "' + todaysDate + '", "end" : "' + todaysDate + '"}';
            this.requestData();
        },
        doSort: function(col_index, direction){
            this.requestQueue.order = [{ column: col_index, dir: direction }];
            this.requestData();
        },
        updateRowsPerPage: function(rows_per_page){
            this.requestQueue.start = 0;
            this.requestQueue.length = rows_per_page;
            this.requestData();
        },
        filterClear: function(soft){
            var results = {};
            for(var mf in this.filters)
            {
                this.filters[mf].result = '';
                results[this.filters[mf].name] = '';
            }
            for(var mf in this.shortcutFilters)
            {
                this.shortcutFilters[mf].result = '';
                results[this.shortcutFilters[mf].name] = '';
            }
            
            this.filterUpdate(results, 0, soft);
        },
        filterUpdate: function(filter_data, total_applied, soft){
            
            this.totalFiltersApplied = total_applied;
            
            if(this.tableId && this.saveState)
            {
                if(this.totalFiltersApplied > 0)
                {
                    localStorage['table_state_filters_'+this.tableId] = JSON.stringify({
                        results: filter_data,
                        applied: this.totalFiltersApplied,
                        savedOn: moment()
                    });
                }
                else if(localStorage['table_state_filters_'+this.tableId])
                {
                    localStorage.removeItem('table_state_filters_'+this.tableId);
                }
            }
            
            this.requestQueue.filters = filter_data;
            if(!soft)
            {
                this.requestData();
            }
        },
        onFilterUpdate: function(values){
            
            values = JSON.parse(JSON.stringify(values));

            //Clear all current filters
            this.filterClear(true);
            
            //Build a filter data array with empty values
            var results = {};
            for(var f in this.filters)
            {
                results[this.filters[f].name] = "";
            }
            for(var f in this.shortcutFilters)
            {
                results[this.shortcutFilters[f].name] = "";
            }
            
            //Populate results array with applied values
            for(var f in values)
            {
                if(typeof results[f] !== 'undefined')
                {
                    results[f] = values[f].result;
                }
            }
            
            //Count number of filters applied
            var applied = 0;
            for(var f in values)
            {
                for(var mf in this.filters)
                {
                    if(f == this.filters[mf].name && values[f].applied)
                    {
                        applied++;
                    }
                }
            }
            
            //Populate dialog filter and shortcut filter values with new values
            for(var f in values)
            {
                for(var mf in this.filters)
                {
                    if(this.filters[mf].name == f)
                    {
                        this.filters[mf].result = values[f].result;
                    }
                }
                for(var mf in this.shortcutFilters)
                {
                    if(this.shortcutFilters[mf].name == f)
                    {
                        this.shortcutFilters[mf].result = values[f].result;
                    }
                }
            }
            
            this.filterUpdate(results, applied);
        },
        redrawShortcutFilters: function(){
            this.filterUpdateCount += 1;
        },
        showFiltersDialog: function(){
            var me = this;
            this.$modal.show(DFiltersDialog, {
                dialogTitle: "Filter Options",
                filters: this.filters,
                onFilterClear: function(){
                    me.filterClear();
                    me.redrawShortcutFilters();
                },
                onFilterUpdate: function(values){
                    me.onFilterUpdate(values);
                    me.redrawShortcutFilters();
                }
            },{ 
                height: 'auto',
                scrollable: true,
                adaptive: true
            });
        },
        reRequestData: function()
        {
            this.requestData();
        }
    },
    mounted: function(){
        this.state = "mounted";
        if(this.filters.length > 0 || this.shortcutFilters.length > 0)
        {
            var state_filters = false;
            var state_save = localStorage['table_state_filters_'+this.tableId];
            if(this.tableId && this.saveState && state_save)
            {
                state_save = JSON.parse(state_save);
                var state_duration = moment.duration(moment().diff(state_save.savedOn));
                if(state_save.results && state_save.applied && parseInt(state_save.applied) > 0 && state_duration.asMinutes() <= 120)
                {
                    this.totalFiltersApplied = parseInt(state_save.applied);
                    state_filters = state_save.results;
                }
            }
            
            var filters_data = {};
            for(var f in this.filters)
            {
                if(typeof filters_data[this.filters[f].name] == 'undefined')
                {
                    filters_data[this.filters[f].name] = null;
                }
                var saved_result = (state_filters[this.filters[f].name]) ? state_filters[this.filters[f].name] : false;
                if(this.tableId && this.saveState && saved_result)
                {
                    filters_data[this.filters[f].name] = saved_result;
                    this.filters[f].result = saved_result;
                }
            }
            for(var f in this.shortcutFilters)
            {
                if(typeof filters_data[this.shortcutFilters[f].name] == 'undefined')
                {
                    filters_data[this.shortcutFilters[f].name] = null;
                }
                var saved_result = (state_filters[this.shortcutFilters[f].name]) ? state_filters[this.shortcutFilters[f].name] : false;
                if(this.tableId && this.saveState && saved_result)
                {
                    filters_data[this.shortcutFilters[f].name] = saved_result;
                    this.shortcutFilters[f].result = saved_result;
                }
            }
            this.requestQueue.filters = JSON.parse(JSON.stringify(filters_data));
        }
        this.redrawShortcutFilters();
        this.requestData();
    }
}
</script>
<style>
    .pickup-confirm-form .datepicker-calendar {
        z-index: 3000
    }
    
    .vue__time-picker .dropdown ul li:not([disabled]).active, 
    .vue__time-picker .dropdown ul li:not([disabled]).active:focus, 
    .vue__time-picker .dropdown ul li:not([disabled]).active:hover{
        background: #3490dc;
    }
</style>
<template>
<dialog-modal v-on="$listeners">
    <strong slot="header">Confirm Pickup<br /><small>Hawb Number: {{ hawbNumber }}</small></strong>
    <form slot="body" name="pickup-confirm-form" v-on:submit.prevent>
        <div class="row">
            <div class="col-sm-7">
                <strong>Pickup Date &amp; Time</strong><hr class="mt-1 mb-2" />
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Date</label>
                    <div class="col-sm-8">
					 <datepicker typeable
                            v-bind:input-class="{ 'is-invalid': submitted && $v.confirmation.pickup_date.$error, 'form-control':true, 'form-control-sm':true, 'color-bg-white':true }" 
                            v-bind:calendar-class="'datepicker-calendar'" 
                            v-bind:bootstrap-styling="true" 
                            v-model="confirmation.pickup_date" 
							format="MM/dd/yyyy"
                        ></datepicker>
						 <small id="pickup_confirm_date" class="text-danger" style="display:none;">
          Date is in the wrong format, must be MM/DD/YYYY.
        </small>   
                        <div v-if="submitted && $v.confirmation.pickup_date.$error" class="invalid-feedback">
                            <span v-if="!$v.confirmation.pickup_date.required">Please provide the pickup date.</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Time In</label>
                    <div class="col-sm-8">
                        <vue-timepicker manual-input hide-dropdown advanced-keyboard
						 input-class="form-control form-control-sm color-bg-white" 
                            input-width="100%"
                            v-model="confirmation.pickup_time_in" 
							hour-label="hour" 
                            minute-label="minute" 
							placeholder="Pickup In Time"
							>
                        </vue-timepicker>
						 <div v-if="submitted && $v.confirmation.pickup_time_in.$error" style="font-size: 80%; color: #e3342f;">
                            <span v-if="!$v.confirmation.pickup_time_in.required">Please provide the time in.</span>
                            <span v-if="!$v.confirmation.pickup_time_in.isTimeFullySelected">Please select both hour and minute.</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Time Out</label>
                    <div class="col-sm-8">
                         <vue-timepicker manual-input hide-dropdown advanced-keyboard
						  input-class="form-control form-control-sm color-bg-white" 
                            input-width="100%"
                            v-model="confirmation.pickup_time_out" 
                            hour-label="hour" 
                            minute-label="minute" 
							placeholder="Pickup Out Time"
                            >
                        </vue-timepicker>
                        <div v-if="submitted && $v.confirmation.pickup_time_out.$error" style="font-size: 80%; color: #e3342f;">
                            <span v-if="!$v.confirmation.pickup_time_out.required">Please provide the time out.</span>
                            <span v-if="!$v.confirmation.pickup_time_out.isTimeFullySelected">Please select both hour and minute.</span>
                            <span v-if="!$v.confirmation.pickup_time_out.isAfterTimeIn">Time out must be equal to or greater than time in.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-5">
                <strong>Shipment</strong><hr class="mt-1 mb-2" />
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Pieces</label>
                    <div class="col-sm-8">
                        <input
                            :class="{ 'is-invalid': submitted && $v.confirmation.pieces.$error, 'form-control':true, 'form-control-sm':true }"
                            v-bind:disabled="!isInternalAgent"
                            v-model="confirmation.pieces" />
                        <div v-if="submitted && $v.confirmation.pieces.$error" class="invalid-feedback">
                            <span v-if="!$v.confirmation.pieces.required">Please provide the pieces.</span>
                            <span v-if="!$v.confirmation.pieces.integer">Pieces must be a valid integer.</span>
                            <span v-if="!$v.confirmation.pieces.minValue">Pieces must be greater than 0</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Weight</label>
                    <div class="col-sm-8">
                        <input
                            :class="{ 'is-invalid': submitted && $v.confirmation.weight.$error, 'form-control':true, 'form-control-sm':true }"
                            v-bind:disabled="!isInternalAgent"
                            v-model="confirmation.weight" />
                        <div v-if="submitted && $v.confirmation.weight.$error" class="invalid-feedback">
                            <span v-if="!$v.confirmation.weight.required">Please provide the weight.</span>
                            <span v-if="!$v.confirmation.weight.integer">Weight must be a valid integer.</span>
                            <span v-if="!$v.confirmation.weight.minValue">Weight must be greater than 0</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Pallets</label>
                    <div class="col-sm-8">
                        <input
                            :class="{ 'is-invalid': submitted && $v.confirmation.pallets.$error, 'form-control':true, 'form-control-sm':true }"
                            v-bind:disabled="!isInternalAgent"
                            v-model="confirmation.pallets" />
                        <div v-if="submitted && $v.confirmation.pallets.$error" class="invalid-feedback">
                            <span v-if="!$v.confirmation.pallets.integer || !$v.confirmation.pallets.minValue">Pallets must be a valid integer.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <strong>Driver &amp; Truck</strong><hr class="mt-1 mb-2" />
        <div class="row">
            <div class="col-sm-7">
                <div class="form-group">
                    <label class="col-form-label">Drivers Full Name</label>
                    <select
                        :class="{ 'is-invalid': false, 'form-control':true, 'form-control-sm':true }"
                        v-model="confirmation.driver_id">
                        <option v-for="driver in driverOptions" v-bind:key="driver.value" v-bind:value="driver.value">{{ driver.text }}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-5">
                <div class="form-group">
                    <label class="col-form-label">Truck Number</label>
                    <input
                        :class="{ 'is-invalid': false, 'form-control':true, 'form-control-sm':true }"
                        v-model="confirmation.truck_number" />
                </div>
            </div>
        </div>
    </form>
    <button slot="footer" v-on:click="submit" type="submit" class="btn btn-primary btn-sm float-right" v-bind:disabled="isSubmitting">
        <span v-if="isSubmitting">Submitting pick up confirmation...</span>
        <span v-else>Confirm Pickup</span>
    </button>
    <button slot="footer" v-on:click="$emit('close')" type="button" class="btn btn-secondary btn-sm float-left" v-bind:disabled="isSubmitting">Cancel</button>
</dialog-modal>
</template>
<script>
import { required, integer, minValue, minLength } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker';
import VueTimepicker from 'vue2-timepicker'; 
import 'vue2-timepicker/dist/VueTimepicker.css';
import Dialog from './DialogModal';
export default {
    components: {
        Datepicker,
        VueTimepicker
    },
    props: {
        hawbId: { type: Number, default: 0 },
        hawbNumber: { type: String, default: '' },
        pickupConfirmDate: { type: String, default: '' },
        timeIn: { type: String, default: '' },
        timeOut: { type: String, default: '' },
        pieces: { type: Number, default: 0 },
        weight: { type: Number, default: 0 },
        pallets: { type: Number, default: 0 },
        driverId: { type: String, default: '' },
        truckNumber: { type: String, default: '' },
        onSubmit: { type: Function, default: function(){} }
    },
    data: function(){
        return {
            confirmation: {
                hawb_id: this.hawbId,
                hawb_number: this.hawbNumber,
                pickup_date: this.pickupConfirmDate,
                pickup_time_in: this.timeIn,
                pickup_time_out: this.timeOut,
                pieces: this.pieces ? this.pieces : '',
                weight: this.weight ? this.weight : '',
                pallets: this.pallets ? this.pallets : '',
                driver_id: this.driverId,
                truck_number: this.truckNumber
            },
            driverOptions: [],
            submitted: false,
            isSubmitting: false
        };
    },
    validations: {
        confirmation: {
            hawb_id: { required },
            pickup_date: { required },
            pieces: { required, integer, minValue: minValue(1) },
            weight: { required, integer, minValue: minValue(1) },
            pallets: { integer, minValue: minValue(0) },
            pickup_time_in: { 
                required,
                isTimeFullySelected: function(){ //Checks that both the HH and mm are selected, ex: "10:mm" is bad
                    if(this.confirmation.pickup_time_in && !(/^\d+$/.test(this.confirmation.pickup_time_in.replace(":",""))))
                    {
                        return false;
                    }
                    return true;
                } 
            },
            pickup_time_out: { 
                required,
                isAfterTimeIn: function(){
                    if(this.confirmation.pickup_time_in && this.confirmation.pickup_time_out)
                    {
                        var d = moment().format("YYYY-MM-DD")+" ";
                        var time_in = moment(d+this.confirmation.pickup_time_in, 'YYYY-MM-DD HH:mm');
                        var time_out = moment(d+this.confirmation.pickup_time_out, 'YYYY-MM-DD HH:mm');
                        if(time_in.isAfter(time_out))
                        {
                            return false;
                        }
                    }
                    return true;
                },
                isTimeFullySelected: function(){ //Checks that both the HH and mm are selected, ex: "HH:30" is bad
                    if(this.confirmation.pickup_time_out && !(/^\d+$/.test(this.confirmation.pickup_time_out.replace(":",""))))
                    {
                        return false;
                    }
                    return true;
                }
            }
        }
    },
    computed: {
        isInternalAgent: function(){
            if(this.$store.getters["user/hasRole"]("internal_agent"))
            {
                return true;
            }
            return false;
        }
    },
    methods: {
        submit: function(){
            var me = this;
            this.submitted = true;
            this.$v.$touch();
            if(this.$v.$invalid)
            {
                this.$modal.show(Dialog, {
                    title: "Form Invalid",
                    message: "Form is invalid, Please fix and click Submit.",
                    type: 'danger'
                },{
                    height: 'auto',
                    adaptive: true
                });
            }
            else
            {
                //Confirm Pickup
                me.isSubmitting = true;
                var confirmation = JSON.parse(JSON.stringify(this.confirmation));
                confirmation.driver_id = confirmation.driver_id.split(",").slice(-1).pop();
                confirmation.pickup_date = moment(confirmation.pickup_date).format("YYYY-MM-DD");
                
                axios.post('/pickup/confirm', qs.stringify(confirmation), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    me.isSubmitting = false;
                    if(response.data && response.data.success)
                    {
                        me.$modal.show(Dialog, {
                            title: "Form Submitted",
                            message: response.data.message,
                            type: 'success'
                        },{
                            height: 'auto',
                            adaptive: true
                        });
                        me.onSubmit(JSON.parse(JSON.stringify(confirmation)));
                        me.$emit('close');
                    }
                    else
                    {
                        me.notifyUnsuccessfulSubmit();
                    }
                }, function(){
                    me.isSubmitting = false;
                    me.notifyUnsuccessfulSubmit();
                });
                
            }
        },
        notifyUnsuccessfulSubmit: function(){
            this.$modal.show(Dialog, {
                title: "Form Submit Unsuccessful",
                message: '<div class="text-center">Error submitting form data!<br />Please refresh the page and try again.</div>',
                type: 'danger',
                buttons: [{
                    label: "OK",
                    align: "right",
                    type: "danger",
                    action: function(dialog){
                        dialog.$emit("close");
                    }
                }]
            },{
                height: 'auto',
                adaptive: true
            });
        }
    },
    mounted: function()
    {
        var me = this;
        
        //Get drivers list
        axios.post('/pickups/drivers', {}, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(response){
            if(response.data && typeof response.data.success)
            {
                me.driverOptions = response.data.data;
                
                //Check if the driver is set for this hawb and try to map the driver combo to it
                if(me.driverId)
                {
                    for(var d in me.driverOptions)
                    {
                        if(me.driverOptions[d].value.split(',').indexOf(me.driverId) >= 0)
                        {
                            me.confirmation.driver_id = me.driverOptions[d].value;
                        }
                    }
                }
            }
        });
		


		$("body").on("blur", ".vdp-datepicker input", function(e){
			var enterDate = $(this).val();
			var date_regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
			
			if(!date_regex.test(enterDate)) {
				$("#pickup_confirm_date").css("display","block");
				$(this).css("border","1px solid red");
			  }else{
				$("#pickup_confirm_date").css("display","none");
				$(this).css("border","1px solid #d2d2d2");
			  }
		});
		
    }
	
	
}
</script>
<style>
    .delivery-confirm-form .datepicker-calendar {
        z-index: 3000
    }
</style>
<template>
<dialog-modal v-on="$listeners">
    <strong slot="header">Confirm Delivery<br /><small>Hawb Number: {{ $data.confirmation["hawb_number"] }}</small></strong>
    <form slot="body" name="delivery-confirm-form" v-on:submit.prevent>
        <div class="row">
            <div class="col-sm-12">
                <strong>Delivery</strong><hr class="mt-1 mb-2" />
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">POD Date</label>
                    <div class="col-sm-9">
                         <datepicker typeable
                            v-bind:input-class="{ 'is-invalid': submitted && $v.confirmation.pod_date.$error, 'form-control':true, 'form-control-sm':true, 'color-bg-white':true }" 
                            v-bind:calendar-class="'datepicker-calendar'" 
                            v-bind:bootstrap-styling="true" 
                            v-model="confirmation.pod_date"
							format="MM/dd/yyyy"
                        ></datepicker>
						<small id="deliver_confirm_date" class="text-danger" style="display:none;">
          Date is in the wrong format, must be MM/DD/YYYY.
        </small>   
                        <div v-if="submitted && $v.confirmation.pod_date.$error" class="invalid-feedback">
                            <span v-if="!$v.confirmation.pod_date.required">Please provide the POD date.</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Time In</label>
                    <div class="col-sm-9">
                        <vue-timepicker manual-input hide-dropdown advanced-keyboard
                           
                            input-width="100%"
                            v-model="confirmation.pod_time_in" 
                           hour-label="hour" 
                            minute-label="minute" 
							placeholder="Delivery In Time">
                        </vue-timepicker>
                        <div v-if="submitted && $v.confirmation.pod_time_in.$error" style="font-size: 80%; color: #e3342f;">
                            <span v-if="!$v.confirmation.pod_time_in.required">Please provide the Time In.</span>
                            <span v-if="!$v.confirmation.pod_time_in.isTimeFullySelected">Please select both hour and minute.</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Time Out</label>
                    <div class="col-sm-9">
                        <vue-timepicker manual-input hide-dropdown advanced-keyboard
                           
                            input-width="100%"
                            v-model="confirmation.pod_time_out" 
                             hour-label="hour" 
                            minute-label="minute" 
							placeholder="Delivery Out Time">
                        </vue-timepicker>
                        <div v-if="submitted && $v.confirmation.pod_time_out.$error" style="font-size: 80%; color: #e3342f;">
                            <span v-if="!$v.confirmation.pod_time_out.required">Please provide the Time Out.</span>
                            <span v-if="!$v.confirmation.pod_time_out.isTimeFullySelected">Please select both hour and minute.</span>
                            <span v-if="!$v.confirmation.pod_time_out.isAfterTimeIn">Time out must be equal to or greater than time in.</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">POD Name</label>
                    <div class="col-sm-9">
                        <input
                            :class="{ 'is-invalid': submitted && $v.confirmation.pod_name.$error, 'form-control':true, 'form-control-sm':true }"
                            v-model="confirmation.pod_name" />
                        <div v-if="submitted && $v.confirmation.pod_name.$error" class="invalid-feedback">
                            <span v-if="!$v.confirmation.pod_name.required">Please provide the POD name.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <button slot="footer" v-on:click="submit" type="button" class="btn btn-primary btn-sm float-right" v-bind:disabled="isSubmitting">
        <span v-if="isSubmitting">Submitting delivery confirmation...</span>
        <span v-else>Confirm Delivery</span>
    </button>
    <button slot="footer" v-on:click="$emit('close')" type="button" class="btn btn-secondary btn-sm float-left" v-bind:disabled="isSubmitting">Cancel</button>
</dialog-modal>
</template>
<script>
import { required, integer, minValue } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker';
import VueTimepicker from 'vue2-timepicker'; import 'vue2-timepicker/dist/VueTimepicker.css';
import Dialog from './DialogModal';
export default {
    components: {
        Datepicker,
        VueTimepicker
    },
    props: {
        hawbId: { type: Number, default: 0 },
        hawbNumber: { type: String, default: '' },
        podDate: { type: String, default: '' },
        podTimeIn: { type: String, default: '' },
        podTimeOut: { type: String, default: '' },
        podName: { type: String, default: '' },
        onSubmit: { type: Function, default: function(){} }
    },
    data: function(){
        return {
            confirmation: {
                hawb_id: this.hawbId,
                hawb_number: this.hawbNumber,
                pod_date: this.podDate,
                pod_time_in: this.podTimeIn,
                pod_time_out: this.podTimeOut,
                pod_name: this.podName,
            },
            submitted: false,
            isSubmitting: false
        };
    },
    validations: {
        confirmation: {
            hawb_id: { required },
            pod_date: { required },
            pod_time_in: { 
                required, 
                isTimeFullySelected: function(){ //Checks that both the HH and mm are selected, ex: "10:mm" is bad
                    if(this.confirmation.pod_time_in && !(/^\d+$/.test(this.confirmation.pod_time_in.replace(":",""))))
                    {
                        return false;
                    }
                    return true;
                }
            },
            pod_time_out: { 
                required,
                isTimeFullySelected: function(){ //Checks that both the HH and mm are selected, ex: "10:mm" is bad
                    if(this.confirmation.pod_time_out && !(/^\d+$/.test(this.confirmation.pod_time_out.replace(":",""))))
                    {
                        return false;
                    }
                    return true;
                },
                isAfterTimeIn: function(){
                    if(this.confirmation.pod_time_in && this.confirmation.pod_time_out)
                    {
                        var d = moment().format("YYYY-MM-DD")+" ";
                        var time_in = moment(d+this.confirmation.pod_time_in, 'YYYY-MM-DD HH:mm');
                        var time_out = moment(d+this.confirmation.pod_time_out, 'YYYY-MM-DD HH:mm');
                        if(time_in.isAfter(time_out))
                        {
                            return false;
                        }
                    }
                    return true;
                }
            },
            pod_name: { required },
        }
    },
    methods: {
        submit: function(){
            
            var me = this;
            this.submitted = true;
            this.$v.$touch();
            if(this.$v.$invalid)
            {
                this.$modal.show(Dialog, {
                    title: "Form Invalid",
                    message: "Form is invalid, Please fix and click Submit.",
                    type: 'danger'
                },{
                    height: 'auto',
                    adaptive: true
                });
            }
            else
            {
                //Confirm Delivery
                me.isSubmitting = true;
                
                var submitData = JSON.parse(JSON.stringify(me.confirmation));
                submitData.pod_date = moment(submitData.pod_date).format("YYYY-MM-DD");
                
                axios.post('/delivery/confirm', qs.stringify(submitData), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    me.isSubmitting = false;
                    if(response.data && response.data.success)
                    {
                        me.$modal.show(Dialog, {
                            title: "Form Submitted",
                            message: response.data.message,
                            type: 'success'
                        },{
                            height: 'auto',
                            adaptive: true
                        });
                        
                        me.onSubmit(submitData);
                        me.$emit('close');
                    }
                    else
                    {
                        me.notifyUnsuccessfulSubmit();
                    }
                }, function(){
                    me.isSubmitting = false;
                    me.notifyUnsuccessfulSubmit();
                });
            }
        },
        notifyUnsuccessfulSubmit: function(){
            this.$modal.show(Dialog, {
                title: "Form Submit Unsuccessful",
                message: '<div class="text-center">Error submitting form data!<br />Please refresh the page and try again.</div>',
                type: 'danger',
                buttons: [{
                    label: "OK",
                    align: "right",
                    type: "danger",
                    action: function(dialog){
                        dialog.$emit("close");
                    }
                }]
            },{
                height: 'auto',
                adaptive: true
            });
        }
    },
	mounted: function(){
		
		$("body").on("blur", ".vdp-datepicker input", function(e){
			var enterDate = $(this).val();
			var date_regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
			
			if(!date_regex.test(enterDate)) {
				
				$("#deliver_confirm_date").css("display","block");
				$(this).css("border","1px solid red");
			  }else{
				$("#deliver_confirm_date").css("display","none");
				$(this).css("border","1px solid #d2d2d2");
			  }
		});
	}
}
</script>
<template>
    <div v-if="agents.length > 0" class="list-group">
        <li v-for="agent in agents" v-bind:key="agent.id" class="list-group-item flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{agent.name}}</h5>
                <small v-if="agent.number" class="text-muted">
                    <small><strong>NUMBER:</strong></small>
                    <span class="text-monospace"> {{agent.number}}</span>
                </small>
            </div>
            <p class="mb-1">
                <span v-if="agent.address_1 && agent.address_1.trim()">{{agent.address_1}}<br /></span>
                <span v-if="agent.city && agent.city.trim()">{{agent.city}}, </span>
                <span v-if="agent.state && agent.state.trim()">{{agent.state}} </span>
                <span v-if="agent.zip && agent.zip.trim()">{{agent.zip}}</span>
                <span v-if="agent.country_name && agent.country_name.trim()"><br />{{agent.country_name}}</span>
            </p>
            <small v-if="agent.contact.trim() > 0" class="text-muted pr-1">
                <small><strong>CONTACT:</strong></small>
                <span class="text-monospace"> {{agent.contact}}</span><br />
            </small>
            <small v-if="agent.phone_nbr.trim()" class="text-muted pr-1">
                <small><strong>PHONE:</strong></small> 
                <span class="text-monospace">{{agent.phone_nbr}}</span>
            </small>
            <button v-if="allowRemove" v-on:click="removeAgent(agent)" type="button" class="btn btn-sm btn-outline-secondary float-right"><i class="fas fa-minus-square"></i> Remove</button>
        </li>
    </div>
</template>
<script>
export default {
    props: {
        agents: { type: Array, default: function(){ return []; }},
        allowRemove: { type: Boolean, default: false },
    },
    methods: {
        removeAgent: function(agent){
            this.$emit('remove-agent', agent);
        },
    }
}
</script>
<template>
    <regular-page v-bind:pageTitle="'Manage Users'">
        <div class="row">
            <div class="col-xs-12 w-100">
                <user-actions v-bind="{ showEdit:false, showDelete:false }" v-on:create-user="createUser"></user-actions>
                <d-table 
                    ref="user_table"
                    v-bind:serverSide="true"
                    v-bind:paging="true"
                    v-bind:pageLength="25"
                    v-bind:scrollY="'400px'"
                    v-bind:ajax="'/user/list'"
                    v-bind:columns="columns"
                    v-bind:tableClass="{'table-sm':true}"
                    v-on:edit-user="editUser"
                    v-on:delete-user="deleteUserConfirm"
                    class="mt-2"
                ></d-table>
            </div>
        </div>
    </regular-page>
</template>

<script>
    import DTable from './dtable/Table';
    import Dialog from './DialogModal';
    import UserDialog from './UserDialog';
    export default {
        params: ["users"],
        components: {
            'd-table': DTable
        },
        data: function(){
            return {
                columns: [{ 
                    title: 'Username', 
                    data: 'username', 
                    name: 'username'
                },{ 
                    title: 'Email', 
                    data: 'email', 
                    name: 'email'
                },{ 
                    title: 'Remit Agent(s)', 
                    data: 'bill_to_ids', 
                    name: 'bill_to_ids'
                },{ 
                    title: 'Terms Agreed', 
                    data: 'terms_agreed', 
                    name: 'terms_agreed',
                    renderer: 'yes-no'
                },{ 
                    title: 'Last Login', 
                    data: 'last_html_request', 
                    name: 'last_html_request',
                    renderer: 'last-activity',
                    rendererProps:{
                         login: "{{row.last_html_request}}"
                    }
                },{ 
                    title: 'Logins', 
                    data: 'login_total', 
                    name: 'login_total'
                },{ 
                    title: 'Enabled', 
                    data: 'enabled', 
                    name: 'enabled',
                    renderer: 'yes-no'
                },{ 
                    title: 'Created', 
                    data: 'created_at', 
                    name: 'created_at',
                    renderer: 'moment'
                },{ 
                    title: 'Modified', 
                    data: 'updated_at', 
                    name: 'updated_at',
                    renderer: 'moment'
                },{ 
                    title: 'Actions', 
                    data: 'id', 
                    name: 'id',
                    renderer: 'user-actions',
                    rendererProps: {
                        showCreate: false,
                        disableEdit: false,
                        disableDelete: false,
                        userId: "{{data}}",
                        userName: "{{row.username}}",
                    }
                }]
            }
        },
        methods: {
            createUser: function(){
                var me = this;
                this.$modal.show(UserDialog, {
                    onSubmit: function(){
                        me.$refs.user_table.reRequestData();
                    }
                },{ 
                    height: 'auto',
                    scrollable: true,
                    width: 800,
                    adaptive: true
                });
            },
            editUser: function(user_id){
                var me = this;
                this.$modal.show(UserDialog, {
                    userId: user_id,
                    onSubmit: function(){
                        me.$refs.user_table.reRequestData();
                    }
                },{
                    height: 'auto',
                    scrollable: true,
                    width: 800,
                    adaptive: true
                });
            },
            deleteUserError: function(){
                this.$modal.show(Dialog, {
                    title: "Form Submit Unsuccessful",
                    message: '<div class="text-center">Error deleting user.<br />Please refresh the page and try again.</div>',
                    type: 'danger',
                    buttons: [{
                        label: "OK",
                        align: "right",
                        type: "danger",
                        action: function(dialog){
                            dialog.$emit("close");
                        }
                    }]
                },{
                    height: 'auto',
                    adaptive: true
                });
            },
            deleteUserConfirm: function(user_id, user_name){
                var me = this;
                this.$modal.show(Dialog, {
                    title: "Delete User",
                    message: "Are you sure you would like to delete <b>" + user_name + "</b>?",
                    type: 'warning',
                    buttons: [{
                        label: 'Yes, Delete ' + user_name,
                        action: function(dialog){
                            me.$emit('user-delete-confirmed', user_id);
                            dialog.$emit('close');
                            me.deleteUser(user_id, user_name);
                        }
                    }]
                },{
                    height: 'auto',
                    adaptive: true
                });
            },
            deleteUser: function(user_id, user_name){
                var me = this;
                me.$store.commit("app/overlayMessage", { message: "Deleting <b>" + user_name + "</b>, please wait...", spinner: true });
                axios.get('/user/delete/id/'+user_id, {}, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    me.$store.commit("app/overlayMessage", false);
                    if(response.data && response.data.success)
                    {
                        me.$emit('close');
                        me.$emit('user-deleted', user_id);
                        me.$refs.user_table.reRequestData();
                    }
                    else
                    {
                        me.deleteUserError();
                    }
                }, function(){
                    me.$store.commit("app/overlayMessage", false);
                    me.deleteUserError();
                });
            }
        }
    }
</script>
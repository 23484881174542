<template>
<span v-html="display"></span>
</template>
<script>
export default {
    props: {
        data: {
            type: [String, Number, Boolean, Array, Object],
            default: 'No'
        },
        row: Object,
        name: String,
        address: String
    },
    computed: {
        display: function()
        {
            return this.name + '<br />' + this.address;
        }
    }
}
</script>
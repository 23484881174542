<template>

<dialog-modal v-on="$listeners">
    <strong slot="header">Login As</strong>
               <div slot="body" class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon2" id="user_username" v-on:keyup.13="search">
        <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" id="username_search" v-on:click="search">Search</button>
        </div>
    </div>

    <form slot="body" name="delivery-confirm-form" v-on:submit.prevent style="display:none;" id="login-list">
        <div class="row">
            <div class="col-sm-12 w-100">
                <d-table 
                    ref="user_table"
                    v-bind:serverSide="false"
                    v-bind:fetchData="this.search_user"
                    v-bind:paging="true"
                    v-bind:pageLength=10
                    v-bind:searching="false"
                    v-bind:info="false"
                    v-bind:scrollY="'400px'"
                    v-bind:columns="columns"

                    v-bind:tableClass="{'table-sm':true}"
                    class="mt-2"
                    v-on:user-switched="$emit('close')"
                   
                ></d-table>
            </div>
        </div>
    </form>
    

</dialog-modal>
</template>

<script>
import DTable from './dtable/Table';
export default {
    components: {
        'd-table': DTable
    },
    data: function(){
        var me = this;
          return {
            columns: [{ 
                title: ' ', 
                data: 'id', 
                name: 'id',
                renderer: 'login-as-button',
                rendererProps: {
                    userId: "{{data}}"
                }
            },{ 
                title: 'Username', 
                data: 'username', 
                name: 'username'
            },{ 
                title: 'Email', 
                data: 'email', 
                name: 'email'
            },{ 
                title: 'Remit Agent(s)', 
                data: 'bill_to_ids', 
                name: 'bill_to_ids'
            },{
                title: 'Enabled', 
                data: 'enabled', 
                name: 'enabled',
                renderer: 'yes-no'
            },{ 
                title: 'Created', 
                data: 'created_at', 
                name: 'created_at',
                renderer: 'moment',
                rendererProps: {
                    outputFormat: 'MM/DD/YYYY'
                }
            },{ 
                title: 'Modified', 
                data: 'updated_at', 
                name: 'updated_at',
                renderer: 'moment',
                rendererProps: {
                    outputFormat: 'MM/DD/YYYY'
                }
            }]
        }
    },
     methods:{
        search_user: function(callback){
                var user = $("#user_username").val();
                if(user != '' || user.length >0) {
                   axios.post("/user/getUser", {
                        Name: user
                        }).then(function(response){
                            $("#login-list").css('display','block');
                             callback(response.data.data);
                        });
                }
                else
                {
                    callback([]);
                }
               
        },
        search:function(){
            this.$refs.user_table.updateData();
        }
    }

}
</script>
<template>
<form class="form-inline" v-on:submit.prevent="doSearch">
    <div class="input-group">
        <input 
            v-on:keydown.enter.prevent="doSearch"
            v-model="currentTerm" 
            class="form-control form-control-sm" 
            type="search" 
            placeholder="Search" 
            aria-label="Search">
            
        <div class="input-group-append">
            <button 
                v-on:click.prevent="doSearch"
                class="btn btn-sm btn-secondary my-sm-0" 
                type="submit">
                
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>
</form>
</template>
<script>
export default {
    props: { 
        searchTerm: {
            type: String,
            default: ''
        }
    },
    data: function(){
        return {
            currentTerm: this.searchTerm
        } 
    },
    methods: {
        doSearch: function(){
            this.$emit('search', this.currentTerm);
        }
    }
}
</script>
<style>
    .file-upload .input-wrapper .file-upload-label span.file-upload-icon{
        display: none;
    }
    .file-upload div.input-wrapper{
        height: 46px;
    }
</style>
<template>
<dialog-modal v-bind:closeable="false" v-on="$listeners">
    <strong v-if="dialogTitle" slot="header">{{ dialogTitle }}<span v-if="dialogSubTitle"><br /><small>{{ dialogSubTitle }}</small></span></strong>
    <form slot="body" name="attach-document-form" v-on:submit.prevent>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Type</label>
                    <div class="col-sm-9">
                        <select class="form-control form-control-sm" v-model="documentType">
                            <option v-for="type in docTypeOptions" v-bind:key="type.value" v-bind:value="type.value">{{ type.label }}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label">Document</label>
                    <div class="col-sm-9">
                        <div v-if="this.errorMessage" class="py-2 text-danger">
                            <strong><small>{{ this.errorMessage }}</small></strong>
                        </div>
                        <file-upload 
                            v-if="!this.attachmentId"
                            v-bind:url="'/attachment/upload'" 
                            v-bind:accept="'.'+this.allowedExtentions.join(',.').toLowerCase()"
                            v-bind:max-size="maxSizeBytes" 
                            v-bind:btn-label="'Click here to select a file'" 
                            v-bind:additional-data="additionalUploadData"
                            v-bind:headers="uploadHeaders" 
                            v-on:change="onFileUploadChange" 
                            v-on:error="onFileUploadError"
                            v-on:progress="onFileUploadProgress">
                        </file-upload>
                        <div v-if="this.attachmentId" class="py-2">
                            <i class="fas fa-check text-success"></i> {{ this.uploadFilename }}
                            <button slot="footer" v-on:click="onChangeDocument" type="button" class="btn btn-outline-secondary btn-sm ml-2">Change</button>
                        </div>
                        <small v-if="!this.attachmentId" id="documentHelpBlock" class="form-text text-muted">
                            File types allowed: <span>{{ allowedExtentions.join(', ').toUpperCase() }}</span><br />
                            Maximum allowed file size: {{ this.formatBytes(maxSizeBytes) }}
                        </small>
                    </div>
                </div>
            </div>
        </div>
        
    </form>
    <button v-if="isSubmitting" slot="footer" type="button" class="btn btn-primary btn-sm float-right" disabled="disabled">Please wait...</button>
    <button v-if="!isSubmitting" slot="footer" v-on:click="submit" type="button" class="btn btn-primary btn-sm float-right" v-bind:disabled="inProgress || !attachmentId || !documentType">Submit</button>
    <button slot="footer" v-on:click="$emit('close')" type="button" class="btn btn-secondary btn-sm float-left" v-bind:disabled="inProgress || isSubmitting">Cancel</button>
</dialog-modal>
</template>
<script>
import Dialog from './DialogModal';
export default {
    props: {
        entityType: {
            type: String, //MUST BE EITHER: 'hawb' or 'mawb' //Look at tbl_AttachedImages.EntityType Maps to a number in backend
            required: true
        },
        entityId: {
            type: Number, //MUST BE EITHER hawb ID or mawb ID //Look at tbl_AttachedImages.EntityID
            required: true
        },
        allowedExtentions: { 
            type: Array, //['pdf','jpg','jpeg'] //Look at tbl_AttachedImages.FileType
            required: true 
        },
        docTypeOptions: { 
            type: Array, //[{value: "5", label: "BOL"},{value: "6", label: "POD"}] //Look at tbl_AttachedImages.ImageTypeID
            required: true
        },
        docTypeSelected: { 
            type: String, //For example "5" or "6" etc..
            default: ''
        },
        dialogTitle: {
            type: String,
            default: 'Attach Document'
        },
        dialogSubTitle: {
            type: String,
            default: ''
        },
        maxSizeBytes: {
            type: Number,
            default: 4194304
        },
        onSubmit: { 
            type: Function,
            default: function($success, $attachment){} 
        },
        genericErrorMessage: {
            type: String, //This error message is shown when upload is unsuccessful without a more specific error msg
            default: "Error uploading file, please refresh the page and try again."
        }
    },
    data: function(){
        return {
            attachmentId: false, //Once it is uploaded successfully, it will get a Attachment ID
            uploadFilename: '',
            additionalUploadData: {
                entity_id: this.entityId,
                entity_type: this.entityType
            },
            documentType: (this.docTypeSelected) ? this.docTypeSelected : '',
            errorMessage: '',
            inProgress: false,
            isSubmitting: false
        }
    },
    computed: {
        uploadHeaders: function(){
            return window.axios.defaults.headers.common;
        },
    },
    methods: {
        submit: function(){
            var me = this;
            me.isSubmitting = true;
            axios.post('/attachment/submit', qs.stringify({ 
                attachment_id: me.attachmentId,
                document_type: me.documentType
            }), {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function(response){
                me.isSubmitting = false;
                if(response.data.success)
                {
                    
                    me.$emit("document_attached", response.data.attachment);
                    
                    me.$props.onSubmit({
                        entityId: me.entityId,
                        entityType: me.entityType,
                        attachment: response.data.attachment, 
                    });
                    
                    me.$modal.show(Dialog, {
                        title: "Form Submitted",
                        message: '<div class="text-center">Attachment successfully submitted.</div>',
                        type: 'success',
                        buttons: [{
                            label: "OK",
                            align: "right",
                            type: "success",
                            action: function(dialog){
                                dialog.$emit("close");
                            }
                        }]
                    },{
                        height: 'auto',
                        adaptive: true
                    });
                    
                    me.$emit("close");
                }
                else if(response.data.message)
                {
                    me.notifyUnsuccessfulSubmit(response.data.message);
                }
                else
                {
                    me.notifyUnsuccessfulSubmit();
                }
            }, function(error){
                me.isSubmitting = false;
                me.notifyUnsuccessfulSubmit();
            });
        },
        notifyUnsuccessfulSubmit: function(message){
            this.$modal.show(Dialog, {
                title: "Form Submit Unsuccessful",
                message: message ? message : '<div class="text-center">Error submitting form data!<br />Please refresh the page and try again.</div>',
                type: 'danger',
                buttons: [{
                    label: "OK",
                    align: "right",
                    type: "danger",
                    action: function(dialog){
                        dialog.$emit("close");
                    }
                }]
            },{
                height: 'auto',
                adaptive: true
            });
        },
        formatBytes: function(bytes, decimals = 2){
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        onChangeDocument: function(){
            this.attachmentId = false;
        },
        onFileUploadProgress: function(){
            this.inProgress = true;
        },
        onFileUploadError: function(resp){
            this.inProgress = false;
            this.attachmentId = false;
            this.uploadFilename = '';
            this.errorMessage = (resp && resp.message) ? resp.message : this.genericErrorMessage;
        },
        onFileUploadChange: function(resp){
            this.inProgress = false;
            if(resp && resp.success && resp.attachment_id)
            {
                this.errorMessage = '';
                this.uploadFilename = resp.upload_filename;
                this.attachmentId = resp.attachment_id;
            }
            else
            {
                this.attachmentId = false;
                this.uploadFilename = '';
                this.errorMessage = (resp.message) ? resp.message : this.genericErrorMessage;
            }
        }
    }
}
</script>
<template>
<span>{{ display }}</span>
</template>
<script>
export default {
    props: {
        data: {
            type: [String, Number, Boolean, Array, Object],
            default: 'No'
        },
        row: Object
    },
    computed: {
        display: function()
        {
            return this.data ? "Yes" : "No";
        }
    }
}
</script>
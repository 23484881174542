<template>
<tr>
    <slot></slot>
</tr>
</template>
<script>
import DColumn from './Column';
export default {
    props: { 
        show: Boolean
    }
}
</script>
<template>
<nav class="dev-navbar navbar navbar-expand navbar-light p-0" :class="bgColor">
    <div class="mr-auto pl-1"><span class="navbar-text">{{ environment }} <em v-if="gitBranch"><small>[Branch: {{gitBranch}}]</small></em></span></div>
    <form class="form-inline">
        <router-link class="btn btn-dark btn-sm rounded-0 m-1" v-for="menu_item in menu" v-bind:key="menu_item.id" :to="'/'+menu_item.identifier">
            <i :class="'fas '+menu_item.icon" style="min-width: 20px;"></i> {{ menu_item.label }}
        </router-link>
        <a v-if="userIsElevated" class="btn btn-dark btn-sm rounded-0 m-1" href="#" v-on:click="showLoginAs">
            <i class="fas fa-random" style="min-width: 20px;"></i> Login As
        </a>
    </form>
</nav>
</template>
<script>
import LoginAsDialog from '../LoginAsDialog';
export default {
    computed: {
        bgColor: function(){
            var color = {
                'bg-success': false,
                'bg-info': false,
                'bg-warning': false,
                'bg-danger': false,
                'navbar-light': false,
                'navbar-dark': false
            };
            switch(this.environment)
            {
                case 'Production': color['bg-danger'] = true; color['navbar-dark'] = true; break;
                case 'Staging': color['bg-warning'] = true; color['navbar-light'] = true; break;
                case 'Testing': color['bg-info'] = true; color['navbar-light'] = true; break;
                default: color['bg-success'] = true; color['navbar-light'] = true; break;
            }
            return color;
        },
        environment: function(){
            return this.$store.state.app.environment.charAt(0).toUpperCase() + this.$store.state.app.environment.slice(1);
        },
        gitBranch: function(){
            return this.$store.state.app.git_branch ? this.$store.state.app.git_branch : false;
        },
        userIsElevated: function(){
            //Decide what icon to use for the user
            if(this.$store.state.user.elevated_user)
            {
                return true;
            }
            return false;
        },
        menu: function(){
            var showMenu = [];
            if(this.$store.state.user.elevated_user)
            {
                var fullMenu = JSON.parse(JSON.stringify(this.$store.state.menu.items));
                for(var x = 0; x < fullMenu.length; x++)
                {
                    if(fullMenu[x].identifier == "admin" && fullMenu[x].subs.length > 0)
                    {
                        for(var y = 0; y < fullMenu[x].subs.length; y++)
                        {
                            showMenu.push(fullMenu[x].subs[y]);
                        }
                    }
                }
            }
            return showMenu;
        }
    },
    methods: {
        showLoginAs: function()
        {
            var me = this;
            this.$modal.show(LoginAsDialog, {},{ 
                height: 'auto',
                scrollable: true,
                adaptive: true,
                width: 900
            });
        }
    }
}
</script>
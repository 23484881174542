<template>
    <a 
        href="#"
        v-on:click="summary" 
        type="button" 
        class="btn btn-sm btn-outline-primary" 
        style="white-space: nowrap" 
        data-toggle="tooltip" 
        data-html="true"
        data-placement="top" 
        v-html="html"
        :title="tooltip"
    ></a>
</template>

<script>
    export default {
        props: {
            data: {
                type: [String, Number, Boolean, Array, Object],
                default: ''
            },
            row: Object,
			indicateApptNotes: { 
                type: Boolean, 
                default: false
            }
        },
        computed: {
            tooltip: function(){
                var tooltip = "Click to view shipment details";
                if(this.row.apptnotes && this.indicateApptNotes)
                {
                    tooltip = tooltip + '<br /><br /><i class="far fa-comment-alt"></i> <em>Appointment notes added</em>';
                }
                return tooltip;
            },
            html: function(){
                var html = '<span>'+this.data+'</span>';
                if(this.row.apptnotes && this.indicateApptNotes)
                {
                    html = html + ' <i v-if="row.apptnotes" class="fas fa-comment-alt"></i>';
                }
                return html;
            }
        },
		methods: {
			summary: function()
            {
                this.$emit("hawb-details-click", this.row);
            }
		}
    }
</script>


<style>
    .nav-link{
        border-bottom-width: 2px;
        border-bottom-style: solid;
        border-bottom-color: transparent;
        padding-bottom: 2px;
        -webkit-transition : border 500ms ease-out;
        -moz-transition : border 500ms ease-out;
        -o-transition : border 500ms ease-out;
        transition : border 500ms ease-out;
    }
    .nav-link.active{
        border-bottom-color: #FFFFFF;
    }
    .navbar-brand{
        background-image: url('/img/plc_transparent.png');
        background-position: center;
        background-repeat: no-repeat; 
        background-size: 90px 90px; 
        background-position: left center;
        padding-left: 93px;
    }
</style>
<template>
<nav class="navbar navbar-expand-md navbar-dark bg-dark shadow-sm">
    <div class="container p-0">
        
        <a class="navbar-brand" href="/">Agent Connect Portal</a>
        
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" v-bind:aria-label="$t('general.toggle_navigation')">
            <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" v-for="menu_item in menu" v-bind:key="menu_item.id">
                    <router-link class="nav-link" :to="'/'+menu_item.identifier"><i :class="'fas '+menu_item.icon" style="min-width: 20px;"></i> {{ menu_item.label }}</router-link>
                </li>
            </ul>

            <user-menu class="navbar-nav ml-auto"></user-menu>
        </div>
    </div>
</nav>
</template>
<script>
export default {
    computed: {
        fullMenu: function(){
            return JSON.parse(JSON.stringify(this.$store.state.menu.items));
        },
        menu: function(){
            var showMenu = [];
            var fullMenu = this.fullMenu;
            for(var x = 0; x < fullMenu.length; x++)
            {
                if(fullMenu[x].identifier == "main_menu" && fullMenu[x].subs.length > 0)
                {
                    for(var y = 0; y < fullMenu[x].subs.length; y++)
                    {
                        var sub = fullMenu[x].subs[y];
                        if((sub.roles !== 'undefined') && (sub.roles.length > 0))
                        {
                            var menuRoleNames = [];
                            for(var r in sub.roles)
                            {
                                menuRoleNames.push(sub.roles[r].name);
                            }
                            if(this.$store.getters["user/hasRoleAnyOf"](menuRoleNames))
                            {
                                //keeps dupes out
                                if(JSON.stringify(showMenu).indexOf(sub.identifier) === -1)
                                {
                                    //caveat is probably that top level items shouldn't have similar names, ex.(pickups vs mypickups)
                                    showMenu.push(sub);
                                }
                            }
                        }
                    }
                }
            }
            return showMenu;
        }
    }
}
</script>
<template>
    <div class="btn-group" role="group" aria-label="Basic example">
        <a 
            :href="'/barcodes/hawb?hawb_no='+hawbData.hawb_number+'&qty='+hawbData.pieces+'&wt='+hawbData.weight+'&origin='+hawbData.origin+'&dest='+hawbData.destination+'&carrier='+hawbData.carrier" 
            target="_blank"
            class="btn btn-sm btn-outline-secondary" 
            data-toggle="tooltip" 
            data-placement="top" 
            title="Print Labels"
        >
            <i class="fa fa-barcode"></i>
        </a>
        <a 
            :href="'/pickups/slip/pdf/' + hawbData.hawb_id" 
            class="btn btn-sm btn-outline-secondary" 
            data-toggle="tooltip" 
            data-placement="top" 
            title="Pickup Slip PDF"
        >
            <i class="fa fa-print"></i>
        </a>
        <button 
            v-on:click="confirmPickup" 
            type="button" 
            class="btn btn-sm btn-outline-secondary" 
            :class="{ 'text-success': (pickupConfirmed || pickupProcessing) }" 
            v-bind:disabled="pickupConfirmed || pickupProcessing" 
            data-toggle="tooltip" 
            data-placement="top" 
            :title="confirmTitle"
        >
            <i class="fas fa-check-circle"></i>
        </button>
        <button 
            v-if="!isDocumentsAttached"
            v-on:click="attachDocument" 
            type="button" 
            class="btn btn-sm btn-outline-secondary" 
            data-toggle="tooltip" 
            data-placement="top" 
            :title="'Attach Document'"
        >
            <i class="fas fa-paperclip"></i>
        </button>
        <button 
            v-if="isDocumentsAttached"
            v-on:click="viewAttachments" 
            type="button" 
            class="btn btn-sm text-success btn-outline-secondary" 
            data-toggle="tooltip" 
            data-placement="top" 
            :title="'View and Attach Documents'"
        >
            <i class="fas fa-paperclip"></i>
        </button>
        <button 
            v-on:click="requestAuth" 
            type="button" 
            class="btn btn-sm btn-outline-secondary" 
            data-toggle="tooltip" 
            data-placement="top" 
            title="Request Authorization"
        >
            <i class="fas fa-comment"></i>
        </button>
        <button 
            v-on:click="reportOsd" 
            type="button" 
            class="btn btn-sm btn-outline-secondary" 
            data-toggle="tooltip" 
            data-placement="top" 
            title="Report OS&amp;D or Refusal"
        >
            <i class="fas fa-flag"></i>
        </button>

    </div>
</template>

<script>
    export default {
        props: {
            hawbData: { 
                type: Object, 
                default: function(){ return {
                    hawb_id: 0
                }
            }}
        },
        computed: {
            pickupConfirmed: function(){
                return (this.hawbData.pickup_confirmed == "Yes" || this.hawbData.status == "Pickup Confirmed") ? true : false;
            },
            pickupProcessing: function(){
                return this.hawbData.status == "Processing" ? true : false;
            },
            confirmTitle: function(){
                if(this.pickupProcessing)
                {
                    return "Processing, please wait...";
                }
                else if(this.pickupConfirmed)
                {
                    return "Pickup has been confirmed";
                }
                else
                {
                    return "Confirm Pickup";
                }
            },
            isDocumentsAttached: function(){
                return this.hawbData.attachments.length > 0 ? true : false;
            }
        },
        methods: {
            confirmPickup: function()
            {
                this.$emit("pickup-confirm", this.hawbData);
            },
            attachDocument: function()
            {
                this.$emit("pickup-attach-doc", this.hawbData);
            },
            viewAttachments: function()
            {
                this.$emit("pickup-view-doc", this.hawbData);
            },
            requestAuth: function()
            {
                this.$emit("pickup-request-auth", this.hawbData);
            },
            reportOsd: function()
            {
                this.$emit("pickup-report-osd", this.hawbData);
            }
			
        }
    }
</script>
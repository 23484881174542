<template>
<dialog-modal v-on="$listeners">
    <strong v-if="dialogTitle" slot="header">{{ dialogTitle }}<span v-if="dialogSubTitle"><br /><small>{{ dialogSubTitle }}</small></span></strong>
    <div v-if="inProgress" slot="body" class="text-center"><spinner style="font-size:26px;"></spinner></div>
    <div v-if="errorMessage && !inProgress" slot="body" class="alert alert-danger" role="alert">{{ errorMessage }}</div>
    <div class="list-group" v-if="attachments.length && !errorMessage && !inProgress" slot="body">
        <div v-for="attachment in attachments" :key="attachment.attachment_id" class="list-group-item flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
                <div class="d-flex w-100 flex-column pr-2">
                    <h5 class="mb-1">{{attachment.document_type.replace("_"," ").toUpperCase()}}</h5>
                    <small class="text-muted" v-html="attachmentDate(attachment)"></small>
                </div>
                <div v-if="attachment.ts_attachment_id" class="pr-2">
                    <span class="text-info">
                        <small style="white-space: nowrap;">{{attachment.document_mime_type}}&nbsp;({{ formatBytes(attachment.document_size) }})</small>
                    </span>
                </div>
                <div v-if="attachment.ts_attachment_id" class="mr-auto">
                    <a :href="'/attachment/download/'+attachment.attachment_id" target="_blank" role="button" class="btn btn-sm btn-outline-secondary" aria-disabled="true">
                        <span style="white-space: nowrap;"><i class="fas fa-eye"></i> View</span>
                    </a>
                </div>
                <div v-if="!attachment.ts_attachment_id" class="mr-auto">
                    <div class="alert alert-warning" role="alert">
                        <span style="white-space: nowrap;">
                            <spinner></spinner>&nbsp;&nbsp;Processing this file...<br />
                            <small><em>Please check back in a few mintues.</em></small>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button v-if="onAttachNew" slot="footer" v-on:click="attachNew" type="button" class="btn btn-primary btn-sm float-right"  v-bind:disabled="inProgress"><i class="fas fa-paperclip"></i> Attach New</button>
</dialog-modal>
</template>
<script>
import Dialog from './DialogModal';
export default {
    props: {
        entityType: {
            type: String, //MUST BE EITHER: 'hawb' or 'mawb' //Look at tbl_AttachedImages.EntityType Maps to a number in backend
            required: true
        },
        entityId: {
            type: Number, //MUST BE EITHER hawb ID or mawb ID //Look at tbl_AttachedImages.EntityID
            required: true
        },
        docTypes: { 
            type: Array, //['all','bol','pod'] //Look at Attachments.document_type
            required: true
        },
        dialogTitle: {
            type: String,
            default: 'View Documents'
        },
        dialogSubTitle: {
            type: String,
            default: ''
        },
        onAttachNewProps: {
            type: Object,
            default: function(){ return {}; }
        },
        onAttachNew: {
            type: Function,
            required: false
        }
    },
    data: function(){
        return {
            attachments: [],
            inProgress: false,
            errorMessage: ""
        }
    },
    methods: {
        attachmentDate: function(attachment){
            return "Uploaded at: "+moment(attachment.created_at).format("MM/DD/YYYY HH:mm");
        },
        getList: function(){
            var me = this;
            me.inProgress = true;
            axios.post('/attachment/view_list', qs.stringify({
                entity_id: this.entityId,
                entity_type: this.entityType,
                document_types: this.docTypes,
            }), {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function(response){
                me.inProgress = false;
                if(response.data.success && response.data.attachments)
                {
                    me.attachments = response.data.attachments;
                    me.errorMessage = "";
                }
                else if(response.data.message)
                {
                    me.errorMessage = response.data.message;
                }
                else
                {
                    me.errorMessage = "Unable to retrieve attachments. Please restart your browser and try again.";
                }
            }, function(error){
                me.inProgress = false;
                me.errorMessage = "Error when retrieving attachments. Please restart your browser and try again.";
            });
        },
        attachNew: function(){
            if(this.onAttachNew)
            {
                this.$emit("close");
                this.onAttachNew(this.onAttachNewProps);
            }
        },
        formatBytes: function(bytes, decimals = 2){
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },
        download: function(attachment){
            axios.get("/attachment/download/"+attachment.attachment_id, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: attachment.document_mime_type })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = attachment.ts_filename;
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error);
        }
    },
    mounted: function(){
        var me = this;
        this.getList();
    }
}
</script>
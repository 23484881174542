<template>
<div class="card-img-overlay d-flex flex-column" style="z-index:5; background: rgba(255,255,255,.5)">
    <div class="align-self-center pt-5">
        <span><spinner style="font-size:36px;"></spinner></span><span v-if="message">&nbsp;&nbsp;{{ message }}</span>
    </div>
</div>
</template>
<script>
export default {
    props: {
        message: {
            type: String,
            default: ''
        }
    }
}
</script>
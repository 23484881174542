<template>
<th>
    <div class="btn-group" role="group">
        <button v-if="shrink" v-on:click="sort" class="btn btn-link btn-sm text-dark" v-bind:style="{cursor: cursor, textDecoration:'none', padding: '0'}"  >
            <strong>{{ title }}</strong>
        </button>
        <button v-else v-on:click="sort" class="btn btn-link btn-sm text-dark" v-bind:style="{cursor: cursor, textDecoration:'none', paddingLeft: '0'}"  >
            <strong>{{ title }}</strong>
        </button>

        <button v-if="ordering && orderable && direction === false" v-on:click="sort" class="btn btn-link btn-sm">
            <i class="fas fa-sort" style="color:#DDDDDD;"></i>
        </button>
        <button v-if="ordering && orderable && direction === 'desc'" v-on:click="sort" class="btn btn-link btn-sm">
            <i class="fas fa-sort-amount-up text-dark"></i>
        </button>
        <button v-if="ordering && orderable && direction === 'asc'" v-on:click="sort" class="btn btn-link btn-sm">
            <i class="fas fa-sort-amount-down-alt text-dark"></i>
        </button>
    </div>
</th>
</template>
<script>
export default {
    props: { 
        show: Boolean,
        title: String, 
        data: String, 
        name: String,
        shrink: { type: Boolean, default: false },
        searchable: Boolean,
        ordering: { type: Boolean, default: true },
        orderable: {
            type: Boolean,
            default: false
        },
        render: Function,
        order: Array,
        index: Number
    },
    data: function(){
        return {
            direction: false,
            cursor: this.$props.ordering && this.$props.orderable ? 'pointer' : 'default'
        }
    },
    watch: {
        order: function(new_val, old_val){
            this.direction = false;
            for(var i in new_val)
            {
                if(new_val[i].column == this.index)
                {
                    this.direction = new_val[i].dir;
                }
            }
        }
    },
    methods: {
        sort: function(){
            if(this.ordering && this.orderable)
            {
                var new_dir = "desc";
                if(this.direction == "desc")
                {
                    new_dir = "asc";
                }
                this.$emit("sort", this.index, new_dir);
            }
        }
    }
}
</script>
<template>
<dialog-modal v-on="$listeners">
    <strong slot="header">Request Authorization<br /><small>Hawb Number: {{ hawbNumber }}</small></strong>
    <form slot="body" name="request-auth-form" v-on:submit.prevent>
        <p>
            Please describe your request and click submit. An email will be sent to PLC and you will be contacted shortly. 
        </p>
        <div class="form-group">
            <textarea
            :class="{ 'is-invalid': submitted && $v.request.message.$error, 'form-control':true, 'form-control-sm':true }"
            v-model="request.message"></textarea>
            <div v-if="submitted && $v.request.message.$error" class="invalid-feedback">
                <span v-if="!$v.request.message.required">Please provide a description for your request.</span>
            </div>
        </div>
    </form>
    <button slot="footer" v-on:click="submit" type="button" class="btn btn-primary btn-sm float-right" v-bind:disabled="isSubmitting">
        <span v-if="isSubmitting">Submitting authorization request...</span>
        <span v-else>Submit</span>
    </button>
    <button slot="footer" v-on:click="$emit('close')" type="button" class="btn btn-secondary btn-sm float-left" v-bind:disabled="isSubmitting">Cancel</button>
</dialog-modal>
</template>
<script>
import { required, integer, minValue } from 'vuelidate/lib/validators'
import Dialog from './DialogModal';
export default {
    props: {
        hawbId: { type: Number, default: 0 },
        hawbNumber: { type: String, default: '' },
        agentType: { type: String, required: true }, //Either "pickup" or "delivery"
        onSubmit: { type: Function, default: function(){} }
    },
    data: function(){
        return {
            request: {
                hawb_id: this.hawbId,
                hawb_number: this.hawbNumber,
                message: ''
            },
            submitted: false,
            isSubmitting: false
        };
    },
    validations: {
        request: {
            message: { required }
        }
    },
    methods: {
        submit: function(){
            var me = this;
            this.submitted = true;
            this.$v.$touch();
            if(this.$v.$invalid)
            {
                this.$modal.show(Dialog, {
                    title: "Form Invalid",
                    message: "Form is invalid, Please fix and click Submit.",
                    type: 'danger'
                },{
                    height: 'auto',
                    adaptive: true
                });
            }
            else
            {
                //Confirm Delivery
                me.isSubmitting = true;
                var url = (me.agentType == "pickup") ? "pickups/request_auth" : "deliveries/request_auth";
                axios.post(url, qs.stringify(this.request), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    me.isSubmitting = false;
                    if(response.data && response.data.success)
                    {
                        me.$modal.show(Dialog, {
                            title: "Form Submitted",
                            message: response.data.message,
                            type: 'success'
                        },{
                            height: 'auto',
                            adaptive: true
                        });
                        me.onSubmit(JSON.parse(JSON.stringify(me.request)));
                        me.$emit('close');
                    }
                    else
                    {
                        me.notifyUnsuccessfulSubmit();
                    }
                }, function(){
                    me.isSubmitting = false;
                    me.notifyUnsuccessfulSubmit();
                });
            }
        },
        notifyUnsuccessfulSubmit: function(){
            this.$modal.show(Dialog, {
                title: "Form Submit Unsuccessful",
                message: '<div class="text-center">Error submitting form data!<br />Please refresh the page and try again.</div>',
                type: 'danger',
                buttons: [{
                    label: "OK",
                    align: "right",
                    type: "danger",
                    action: function(dialog){
                        dialog.$emit("close");
                    }
                }]
            },{
                height: 'auto',
                adaptive: true
            });
        }
    }
}
</script>
<template>
    <div ref="shortcut_filter_container">
        <div v-for="filter in filters" v-bind:key="filter.name" class="form-group row justify-content-center">
            <div class="col">
                <div class="row">
                    <label class="col-sm-12 col-form-label rounded-top bg-secondary text-center text-white" v-bind:for="filter.name" v-html="filter.label">
                    </label>
                    <div class="col-sm-12 rounded-bottom bg-secondary border-top p-0">
                        <component 
                            v-bind:is="filter.type"
                            v-on:filter-applied="setFilterApplied(filter.name, true)" 
                            v-on:filter-unapplied="setFilterApplied(filter.name, false)" 
                            v-model="filter_values[filter.name].result" 
                            v-bind="filter.props"
                        ></component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        filters: {
            type: Array
        }
    },
    data: function(){
        
        var filters_data = {};
        for(var f in this.filters)
        {
            filters_data[this.filters[f].name] = {
                result: (this.filters[f].result) ? this.filters[f].result : '',
                applied: (this.filters[f].result) ? true : false
            };
        }
            
        return {
            filter_values: filters_data
        };
    },
    methods: {
        setFilterApplied: function(filter_name, applied)
        {
            this.filter_values[filter_name].applied = applied;
            this.applyFilters();
        },
        getFilterValues: function()
        {
            return this.filter_values;
        },
        clearFilters: function()
        {
            for(var f in this.filters)
            {
                this.filter_values[this.filters[f].name].result = '';
                this.filter_values[this.filters[f].name].applied = false;
            }
            this.$emit('filter-clear');
        },
        applyFilters: function()
        {
            this.$emit('filter-update', this.getFilterValues());
        }
    },
    mounted(){
        var me = this;
        setTimeout(function(){
            if(me.$refs.shortcut_filter_container)
            {
                $(me.$refs.shortcut_filter_container).find('[data-toggle="tooltip"]').tooltip();
            }
        }, 200);
    }
}
</script>
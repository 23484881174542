<template>
<span>Showing {{ startAt }} to {{ endAt }} of {{ recordsFiltered }} records</span>
</template>
<script>
export default {
    props: { 
        recordsTotal: {
            type: Number,
            default: 0
        },
        recordsFiltered: {
            type: Number,
            default: 0
        },
        start: {
            type: Number,
            default: 0
        },
        length: {
            type: Number,
            default: 0
        },
        currentRowCount: {
            type: Number,
            default: 0
        }
    },
    computed: {
        startAt: function(){ 
            var preStartAt = (this.start < 0 || !this.currentRowCount) ? 0 : parseInt(this.start);
            //keeps things from starting at 0
            if(preStartAt == 0){
                return 1;
            }
            return preStartAt;
        },
        endAt: function(){
            if(!this.currentRowCount)
            {
                return 0;
            }
            
            var end = parseInt(this.startAt) + parseInt(this.currentRowCount) - 1;
            return (end > this.recordsFiltered) ? this.recordsFiltered : end; 
        }
    }
}
</script>
<template>
<dialog-modal v-on="$listeners">
    <strong slot="header">{{ chargeType }} charge remark<br /><small>Hawb Number: {{ hawbNumber }}</small></strong>
    <form slot="body" name="request-auth-form" v-on:submit.prevent>
        <p>
            Please input your remark below. An email notification will be sent to PLC.
        </p>
        <hr />
        <div class="form-group row">
            <label class="col-sm-3 col-form-label">Total Charge</label>
            <div class="col-sm-9">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                    </div>
                    <input
                        :class="{ 'is-invalid': submitted && $v.remark.total_charge.$error, 'form-control':true }"
                        v-model="remark.total_charge" />
                    <div v-if="submitted && $v.remark.total_charge.$error" class="invalid-feedback">
                        <span v-if="!$v.remark.total_charge.integer || !$v.remark.total_charge.minValue">Total charge must be a valid amount.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <textarea
            :class="{ 'is-invalid': submitted && $v.remark.message.$error, 'form-control':true, 'form-control-sm':true }"
            v-model="remark.message" placeholder="Please provide a remark here."></textarea>
            <div v-if="submitted && $v.remark.message.$error" class="invalid-feedback">
                <span v-if="!$v.remark.message.maxLength">Remark cannot exceed 1000 characters.</span>
            </div>
        </div>
    </form>
    <button slot="footer" v-on:click="submit" type="button" class="btn btn-primary btn-sm float-right" v-bind:disabled="isSubmitting">
        <span v-if="isSubmitting">Submitting remark...</span>
        <span v-else>Submit</span>
    </button>
    <button slot="footer" v-on:click="$emit('close')" type="button" class="btn btn-secondary btn-sm float-left" v-bind:disabled="isSubmitting">Cancel</button>
</dialog-modal>
</template>
<script>
import { required, decimal, minValue, maxLength } from 'vuelidate/lib/validators'
import Dialog from './DialogModal';
export default {
    props: {
        hawbId: { type: Number, default: 0 },
        hawbNumber: { type: String, default: '' },
        chargeId: { type: Number, default: 0 },
        chargeType: { type: String, default: '' },
        onSubmit: { type: Function, default: function(){} }
    },
    data: function(){
        return {
            remark: {
                hawb_id: this.hawbId,
                hawb_number: this.hawbNumber,
                charge_id: this.chargeId,
                message: '',
                total_charge: ''
            },
            submitted: false,
            isSubmitting: false
        };
    },
    validations: {
        remark: {
            total_charge: { required, decimal, minValue: minValue(1) },
            message: { maxLength: maxLength(1000) }
        }
    },
    methods: {
        submit: function(){
            var me = this;
            this.submitted = true;
            this.$v.$touch();
            if(this.$v.$invalid)
            {
                this.$modal.show(Dialog, {
                    title: "Form Invalid",
                    message: "Form is invalid, Please fix and click Submit.",
                    type: 'danger'
                },{
                    height: 'auto',
                    adaptive: true
                });
            }
            else
            {
                //Confirm Delivery
                me.isSubmitting = true;
                axios.post('/charges/add_remark', qs.stringify(this.remark), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(response){
                    me.isSubmitting = false;
                    if(response.data && response.data.success)
                    {
                        me.$modal.show(Dialog, {
                            title: "Form Submitted",
                            message: response.data.message,
                            type: 'success'
                        },{
                            height: 'auto',
                            adaptive: true
                        });
                        me.onSubmit(JSON.parse(JSON.stringify(me.remark)));
                        me.$emit('close');
                    }
                    else
                    {
                        me.notifyUnsuccessfulSubmit();
                    }
                }, function(){
                    me.isSubmitting = false;
                    me.notifyUnsuccessfulSubmit();
                });
            }
        },
        notifyUnsuccessfulSubmit: function(){
            this.$modal.show(Dialog, {
                title: "Form Submit Unsuccessful",
                message: '<div class="text-center">Error submitting form data!<br />Please refresh the page and try again.</div>',
                type: 'danger',
                buttons: [{
                    label: "OK",
                    align: "right",
                    type: "danger",
                    action: function(dialog){
                        dialog.$emit("close");
                    }
                }]
            },{
                height: 'auto',
                adaptive: true
            });
        }
    }
}
</script>
<template>
    <div v-html="display"></div>
</template>
<script>
export default {
    props: {
        data: {
            type: [String, Number, Boolean, Array, Object],
            default: ''
        },
        row: Object,
        wrapOpen: {
            type: String,
            default: '<span>'
        },
        wrapClose: {
            type: String,
            default: '</span>'
        }
    },
    computed: {
        display: function()
        {
            var value = "";
            switch(typeof this.data)
            {
                case "string": value = this.data ? this.data : ""; break;
                case "number": value = this.data; break;
                case "boolean": value = this.data ? "1" : "0"; break;
                case "array": value = "[array]"; break;
                case "object": value = (this.data === null) ? "" : "[object]"; break;
                default: value = this.data;
            }
            
            return this.wrapOpen + value + this.wrapClose;
        }
    }
}
</script>